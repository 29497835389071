import React from 'react';
import useHyperplanes from '../../services/useHyperplanes';
import StatsBox from './stats-box';
import { HyperplaneEniOverviewPage as page } from '..';
import ErrorBox from './error-box';

type HyperplaneStatsProps = {};

const HyperplaneStats: React.FC<HyperplaneStatsProps> = () => {
  const {
    data: hyperplanes,
    error: hyperplanesError,
    isError: hyperplanesIsError,
    isLoading: hyperplanesIsLoading
  } = useHyperplanes();

  if (hyperplanesIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={hyperplanesError} />
      </React.Fragment>
    );
  }

  const maxHyperplanes = () => {
    let amount = '250';
    if (process.env.REACT_APP_STAGE === 'production') {
      amount = '400';
    } else if (process.env.REACT_APP_STAGE === 'staging') {
      amount = '250';
    }
    return amount;
  };

  return (
    <React.Fragment>
      <StatsBox loading={hyperplanesIsLoading} page={page}>
        {`${hyperplanes?.length}/${maxHyperplanes()}`}
      </StatsBox>
    </React.Fragment>
  );
};

export default HyperplaneStats;

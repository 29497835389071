import React from 'react';
import useAwsResources from '../../services/useAwsResources';
import StatsBox from './stats-box';
import { AwsCloudFrontOverviewPage as page } from '..';
import ErrorBox from './error-box';

type AwsCloudFrontProps = {};

const AwsCloudFrontStats: React.FC<AwsCloudFrontProps> = () => {
  const {
    data: awsCloudFronts,
    error: awsCloudFrontsError,
    isError: awsCloudFrontsIsError,
    isLoading: awsCloudFrontsIsLoading
  } = useAwsResources('AWS::CloudFront::Distribution');

  if (awsCloudFrontsIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={awsCloudFrontsError} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StatsBox loading={awsCloudFrontsIsLoading} page={page}>
        {awsCloudFronts?.resultCount}
      </StatsBox>
    </React.Fragment>
  );
};

export default AwsCloudFrontStats;

import * as React from 'react';
import useAccountsMetadata from '../../services/useAwsAccountsMetadata';
import { Container, Grid, Skeleton, Stack, Tooltip } from '@mui/material';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { DateCell, DateColumnDefinition } from '../../components/tablev2/cells/date-cell';
import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { ICTable } from '../../components/tablev2/ic-table';
import { HeaderGroup, Header } from '../../components/tablev2/types';
import { Identible } from '../../components/tablev2/types';
import moment from 'moment';
import { hash } from '../../hashing';
import Error from '../error';
import { AccountMetadataType } from '../../services/useAwsAccountsMetadata';
import useAccountCosts, { AwsAccountCost } from '../../services/useAwsLastestCosts';

export interface RowAccountData extends Identible {
  accountNumber: StringCell;
  accountEmail: StringCell;
  accountName: StringCell;
  parentOrgUnit: StringCell;
  accountJoinedDate: DateCell;
  accountCosts: NumberCell;
  serviceOwner: StringCell;
  departmentCode: StringCell;
  servicenowSolutionName: StringCell;
  servicenowLastUpdated: DateCell;
}

const awsHeaders: Header<RowAccountData>[] = [
  {
    id: 'HeaderGroup1Header1',
    rowType: 'accountName',
    value: 'Account Name',
    tooltip: 'The name of the AWS account',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header2',
    rowType: 'accountNumber',
    value: 'Account Number',
    tooltip: 'The account number of the AWS account',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header3',
    rowType: 'accountEmail',
    value: 'Account Email',
    tooltip: 'The root user email associated with the AWS account',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header5',
    rowType: 'parentOrgUnit',
    value: 'Org Unit',
    tooltip: 'The parent organization unit of the AWS account',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header6',
    rowType: 'accountJoinedDate',
    value: 'Joined',
    tooltip: 'The date the account joined the AWS organization',
    columnDefinition: DateColumnDefinition
  },
  {
    id: 'HeaderGroup1Header7',
    rowType: 'accountCosts',
    value: 'Cost',
    tooltip: 'Last recorded daily cost of the AWS account. All costs are in USD and without VAT.',
    columnDefinition: NumberColumnDefinition
  }
];

const serviceNowHeaders: Header<RowAccountData>[] = [
  {
    id: 'HeaderGroup2Header1',
    rowType: 'serviceOwner',
    value: 'Service Owner',
    tooltip: 'The service owner of the AWS account',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup2Header2',
    rowType: 'departmentCode',
    value: 'Dpt Code',
    tooltip: 'The department code of the service owner',
    columnDefinition: { ...StringColumnDefinition, sortable: false }
  },
  {
    id: 'HeaderGroup2Header3',
    rowType: 'servicenowSolutionName',
    value: 'Solution Name',
    tooltip: 'The solution name given to the AWS account in ServiceNow',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup2Header4',
    rowType: 'servicenowLastUpdated',
    value: 'Last Updated',
    tooltip: 'The time the account was last updated in ServiceNow',
    columnDefinition: DateColumnDefinition
  }
];

const headersWithGroup: HeaderGroup<RowAccountData>[] = [
  {
    id: 'HeaderGroup1',
    value: 'AWS',
    tooltip: 'Information displayed in this group comes from AWS organizations',
    headers: awsHeaders
  },
  {
    id: 'HeaderGroup2',
    value: 'ServiceNow',
    tooltip: 'Information displayed in this group comes from Service Now',
    headers: serviceNowHeaders
  }
];

const buildAccountRows = (fromData: AccountMetadataType[], costs: AwsAccountCost[] | string): RowAccountData[] => {
  const getCostsForAccount = (accountId: string): AwsAccountCost => {
    // find cost for account id
    if (costs instanceof Array) {
      const cost = costs.find((cost) => cost.accountId === accountId);
      return cost ? cost : { accountId: accountId, cost: '0', costDate: '0' };
    }
    return { accountId: accountId, cost: 'N/A', costDate: 'N/A' };
  };

  const data: RowAccountData[] = [];
  fromData.forEach((type) => {
    const hashedId = `id${hash(type.accountId)}`;
    const cost = getCostsForAccount(type.accountId);
    data.push({
      id: hashedId,
      accountNumber: { value: type.accountId, id: `number${hashedId}` },
      accountEmail: { value: type.accountEmail, id: `email${hashedId}` },
      parentOrgUnit: { value: type.organizationalUnitForAccount, id: `org${hashedId}` },
      accountName: {
        id: `name${hashedId}`,
        value: type.accountName,
        href: `https://signin.aws.amazon.com/switchrole?account=${type.accountId}&roleName=Administrator&displayName=Administrator%40${type.accountName}`,
        target: '_blank'
      },
      accountJoinedDate: {
        value: new Date(type.accountJoinedTimestamp),
        id: `joined${hashedId}`,
        tooltip: `${moment(new Date(type.accountJoinedTimestamp)).format('LLL')}`
      },
      accountCosts: {
        id: `costs${hashedId}`,
        value: Number(parseFloat(cost.cost).toFixed(2)),
        prefix: '$',
        href: `/aws-cost-overview?accountId=${type.accountId}`,
        target: '_self',
        tooltip: `Account costs are updated every night at 10 PM. Last updated: ${moment(
          new Date(cost.costDate)
        ).format('LL')}
        `
      },
      serviceOwner: {
        value: `${type.servicenowBusinessOwnerFirstName} ${type.servicenowBusinessOwnerLastName}`,
        id: `serviceOwner${hashedId}`,
        suffix: ` (${type.servicenowBusinessOwnerInitials})`,
        href: `mailto:${type.servicenowBusinessOwnerEmail}`
      },
      departmentCode: {
        id: `departmentCode${hashedId}`,
        value: type.servicenowBusinessOwnerDepartmentCode,
        tooltip: type.servicenowBusinessOwnerDepartment
      },
      servicenowSolutionName: { value: type.servicenowSolutionName, id: `solutionName${hashedId}` },
      servicenowLastUpdated: {
        value: new Date(type.servicenowLastUpdated),
        id: `lastUpdated${hashedId}`,
        tooltip: `${moment(new Date(type.servicenowLastUpdated)).format('LLL')}`
      }
    });
  });
  return data;
};

function AwsAccountOverview() {
  const {
    data: accountsMetadata,
    error: accountsMetadataError,
    isLoading: accountsMetadataIsLoading,
    isError: accountMetadataIsError,
    dataUpdatedAt: accountMetadataUpdatedAt
  } = useAccountsMetadata();
  const { data: accountCosts, isError: accountCostsIsError } = useAccountCosts();

  if (accountMetadataIsError) {
    return (
      <Error
        customMessage={`Error while trying to fetch account metadata`}
        errorMessage={accountsMetadataError.message}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {accountsMetadataIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        accountsMetadata && (
          <React.Fragment>
            <ICTable
              rows={buildAccountRows(accountsMetadata.result, accountCostsIsError ? 'failed' : accountCosts?.result)}
              headers={headersWithGroup}
              orderBy={'accountName'}
              order={'asc'}
              filterable={true}
              stripedRows={true}
            />
            <Grid container justifyContent="space-between" alignItems="flex-end">
              <span>&nbsp;</span>
              <Tooltip title="Data is not changing very often for these data. New accounts may appear if accounts are ordered through service now, but that is not often. The cost data is undated every 24 hours. These data will only be refetched every 12 hour.">
                <span style={{ fontSize: 'small', color: 'grey' }}>
                  Last time data was fetched from server:{' '}
                  {accountsMetadataIsLoading ? (
                    <strong>fetching...</strong>
                  ) : (
                    <strong>{new Date(accountMetadataUpdatedAt).toLocaleTimeString()}</strong>
                  )}
                </span>
              </Tooltip>
            </Grid>
          </React.Fragment>
        )
      )}
    </Container>
  );
}

export default AwsAccountOverview;

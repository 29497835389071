import * as React from 'react';
import { Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton, Stack } from '@mui/material';
import useCountAwsResources from '../../services/useCountAwsResources';
import useOrganizationAccounts from '../../services/useOrganizationAccounts';
import { ResourceQuantityAllAccounts } from './resource-quantity-all-accounts';
import { ResourceQuantityAccounts } from './resource-quantity-account';
import Error from '../error';

function AwsResourceOverview() {
  const [groupBy, setGroupBy] = React.useState<'account' | 'resource'>('account');
  const {
    data: countAwsResources,
    isLoading: countAwsResourcesLoading,
    error: countAwsResourcesError,
    isError: countAwsResourcesIsError
  } = useCountAwsResources(groupBy);
  const { data: organisationAccounts, isLoading: organisationAccountsIsLoading } = useOrganizationAccounts();

  if (countAwsResourcesIsError) {
    return (
      <Error
        customMessage={`Error while trying to fetch AWS resources`}
        errorMessage={countAwsResourcesError.message}
      />
    );
  }

  const handleChangeGroupBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupBy(event.target.value as 'account' | 'resource');
  };

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {countAwsResourcesLoading || organisationAccountsIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={70}></Skeleton>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        <React.Fragment>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Group by: </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={groupBy}
              onChange={handleChangeGroupBy}
            >
              <FormControlLabel value="resource" control={<Radio />} label="Resource" />
              <FormControlLabel value="account" control={<Radio />} label="Account" />
            </RadioGroup>
          </FormControl>
          {groupBy === 'account'
            ? countAwsResources?.result && (
                <ResourceQuantityAccounts data={countAwsResources.result} accounts={organisationAccounts ?? []} />
              )
            : countAwsResources?.result && <ResourceQuantityAllAccounts data={countAwsResources.result} />}
        </React.Fragment>
      )}
    </Container>
  );
}
export default AwsResourceOverview;

import { Skeleton } from '@mui/material';
import React from 'react';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { ICTable } from '../../components/tablev2/ic-table';
import { Header, HeaderGroup, Identible } from '../../components/tablev2/types';
import { hash } from '../../hashing';
import useAwsResources, { AwsResourceType } from '../../services/useAwsResources';

interface Props {
  accountId: string;
  usedBy: string[];
}

interface CloudfrontRow extends Identible {
  resourceId: StringCell;
  domainName: StringCell;
  aliases: StringCell;
}

type CloudfrontConfiguration = {
  domainName: string;
  aliasICPRecordals: AliasRecord[];
};

type AliasRecord = {
  cNAME: string;
  iCPRecordalStatus: string;
};

const headers: Header<CloudfrontRow>[] = [
  {
    id: 'CloudfrontHeaderGroup1Header1',
    value: 'Resource Id',
    rowType: 'resourceId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CloudfrontHeaderGroup1Header2',
    value: 'Domain Name',
    rowType: 'domainName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CloudfrontHeaderGroup1Header3',
    value: 'Aliases',
    rowType: 'aliases',
    columnDefinition: StringColumnDefinition
  }
];

const headerGroup: HeaderGroup<CloudfrontRow>[] = [
  {
    id: 'CloudfrontHeader1',
    value: 'Used by',
    align: 'left',
    headers
  }
];

const buildRows = (usedBy: string[], awsResources: AwsResourceType[]): CloudfrontRow[] => {
  const data: CloudfrontRow[] = [];
  const cloudfrontResourceIds = usedBy.map((element) => element.split('/')[1]);
  cloudfrontResourceIds.forEach((resourceId) => {
    const hashedId = `id${hash(resourceId)}`;
    const distribtion = awsResources.find((resource) => resource.resourceId === resourceId);
    if (distribtion) {
      const configuration = distribtion.configuration as CloudfrontConfiguration;
      const row: CloudfrontRow = {
        id: hashedId,
        resourceId: { value: distribtion.resourceId, id: `resourceId${hashedId}` },
        domainName: { value: configuration.domainName, id: `domainName${hashedId}` },
        aliases: {
          value: configuration.aliasICPRecordals.map((record) => record.cNAME).join(', '),
          id: `aliases${hashedId}`
        }
      };
      data.push(row);
    } else {
      const row: CloudfrontRow = {
        id: hashedId,
        resourceId: { value: resourceId, id: `resourceId${hashedId}` },
        domainName: { value: 'Cloudfront distribution not found', id: `domainName${hashedId}` },
        aliases: { value: 'N/A', id: `aliases${hashedId}` }
      };
      data.push(row);
    }
  });
  return data;
};

export const CloudfrontDetails: React.FC<Props> = (props) => {
  const {
    data: awsResources,
    error: awsResourcesError,
    isError: awsResourcesIsError,
    isLoading: awsResourcesIsLoading
  } = useAwsResources('AWS::CloudFront::Distribution', props.accountId);
  if (awsResourcesIsError) {
    return <div>We encountered and error {awsResourcesError.message}</div>;
  }

  return (
    <React.Fragment>
      {awsResourcesIsLoading ? (
        <Skeleton></Skeleton>
      ) : (
        awsResources?.result && (
          <ICTable headers={headerGroup} rows={buildRows(props.usedBy, awsResources.result)} orderBy="domainName" />
        )
      )}
    </React.Fragment>
  );
};

import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

export type LoginSummary = {
  successfulSignInCount: number;
  failedSignInCount: number;
  appDisplayName: string;
  successPercentage: number;
};

export type LoginsPayload = {
  totalSuccessfulSignInCount: number;
  totalFailedSignInCount: number;
  loginSummary: LoginSummary[];
};

function useAzureGraphApi(period: string) {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const azureLoginsQuery = useQuery({
    queryKey: ['azureLogins', period],
    queryFn: async () => {
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_READ],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );
      const response = await fetch(endpoint.AZURE_API_ENDPOINT + `/get-login-summary?period=${period}`, {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          'x-top-api-version': 'v1'
        }
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }
  });

  return {
    data: azureLoginsQuery.data as LoginsPayload,
    error: azureLoginsQuery.error as Error,
    isLoading: azureLoginsQuery.isLoading,
    isError: azureLoginsQuery.isError,
    isFetching: azureLoginsQuery.isFetching,
    isSuccess: azureLoginsQuery.isSuccess,
    dataUpdatedAt: azureLoginsQuery.dataUpdatedAt
  };
}

export default useAzureGraphApi;

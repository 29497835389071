import React, { useContext, useEffect, useState } from 'react';
import UserLoginIcon from '../login/user-login-icon';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Box } from '@mui/material';
import ApplicationScopesCtx from '../../context/ApplicationScopesContext';
import { isAdmin } from '../../role-checks';
import { AuthenticationResult } from '@azure/msal-browser';

function LoggedInUser() {
  const { accounts, instance } = useMsal();
  const Scopes = useContext(ApplicationScopesCtx);
  const [jwt, setJwt] = useState<string>('');

  useEffect(() => {
    const fetchJwtAsync = async () => {
      if (accounts.length > 0) {
        const scopes = [Scopes!.OVERVIEW_READ];
        if (isAdmin(instance.getActiveAccount())) {
          scopes.push(Scopes!.OVERVIEW_ADMIN);
        }
        const silentRequest = {
          scopes,
          account: accounts[0]
        };
        console.log('silentRequest', silentRequest);
        const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
        setJwt(token.accessToken);
      }
    };

    fetchJwtAsync();
  }, [accounts, instance, Scopes]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AuthenticatedTemplate>
        <UserLoginIcon
          name={instance.getActiveAccount()?.name || 'Unknown Name'}
          isAdmin={isAdmin(instance.getActiveAccount())}
          jwt={jwt}
          loggedIn={true}
        ></UserLoginIcon>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UserLoginIcon name="Not LoggedIn" loggedIn={false}></UserLoginIcon>
      </UnauthenticatedTemplate>
    </Box>
  );
}

export default LoggedInUser;

import React from 'react';
import CloudIcon from '@mui/icons-material/Cloud';
import LoginIcon from '@mui/icons-material/Login';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CellTowerIcon from '@mui/icons-material/CellTower';
import TrafficIcon from '@mui/icons-material/Traffic';
import HubIcon from '@mui/icons-material/Hub';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { teal, blueGrey, indigo, brown, blue, deepOrange, deepPurple, orange } from '@mui/material/colors';
import { isAdmin } from '../role-checks';
import { AccountInfo } from '@azure/msal-browser';

export interface Page {
  color: Record<number, string>;
  link: string;
  label: string;
  icon: React.FC<{ size: 'small' | 'inherit' | 'large' | 'medium' | undefined; color?: Record<number, string> }>;
  tooltip: string;
  visible: boolean;
  description: string;
  subDescription: string | undefined;
  errorMessage: string;
}

const AwsCostOverviewPage: Page = {
  color: deepOrange,
  label: 'AWS cost overview',
  link: '/aws-cost-overview',
  visible: true,
  icon: (props) => (
    <AttachMoneyIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></AttachMoneyIcon>
  ),
  tooltip: 'Overview of lastest recorded costs for all accounts in USD for a single day',
  description: 'Total AWS cost',
  subDescription: '(Daily cost)',
  errorMessage: 'Failed to load cost data'
};

const AzureLoginOverviewPage: Page = {
  color: indigo,
  label: 'Azure login overview',
  link: '/azure-logins-overview',
  visible: true,
  icon: (props) => <LoginIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></LoginIcon>,
  tooltip: 'Get an overview of succesful and unsuccesful Azure logins',
  description: 'Total logins in Azure AD',
  subDescription: '(last 30 days)',
  errorMessage: 'Failed to load login data'
};

const CertificateOverviewPage: Page = {
  color: brown,
  label: 'Certificate overview',
  link: '/certificate-overview',
  visible: true,
  icon: (props) => (
    <WorkspacePremiumIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></WorkspacePremiumIcon>
  ),
  tooltip: 'Overview of all certificates in AWS',
  description: 'Total number of Certificates in AWS',
  subDescription: undefined,
  errorMessage: 'Failed to load certificate data'
};

const AwsHostedZoneOverviewPage: Page = {
  color: deepPurple,
  label: 'AWS hosted zone overview',
  link: '/aws-hosted-zone-overview',
  visible: true,
  icon: (props) => <TrafficIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></TrafficIcon>,
  tooltip: 'Overview of all AWS hosted zones',
  description: 'Total number of AWS hosted zones',
  subDescription: undefined,
  errorMessage: 'Failed to load hosted zone data'
};

const AwsResourceOverviewPage: Page = {
  color: teal,
  label: 'AWS resource overview',
  link: '/aws-resource-overview',
  visible: true,
  icon: (props) => <CloudIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></CloudIcon>,
  tooltip: 'Overview of all AWS resources',
  description: 'Total number of AWS resources',
  subDescription: undefined,
  errorMessage: 'Failed to load resource data'
};

const HyperplaneEniOverviewPage: Page = {
  color: blue,
  label: 'Hyperplane eni overview',
  link: '/hyperplane-overview',
  visible: true,
  icon: (props) => <CellTowerIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></CellTowerIcon>,
  tooltip: 'Overview of all Hyperplane enis in AWS',
  description: 'AWS Hyperplane enis used',
  subDescription: undefined,
  errorMessage: 'Failed to load Hyperplane data'
};

const AwsAccountOverviewPage: Page = {
  color: blueGrey,
  label: 'AWS account overview',
  link: '/aws-account-overview',
  visible: true,
  icon: (props) => (
    <AccountBalanceIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></AccountBalanceIcon>
  ),
  tooltip: 'Overview of all AWS accounts and their business owners',
  description: 'Total number of AWS accounts',
  subDescription: undefined,
  errorMessage: 'Failed to load account data'
};

const AwsCloudFrontOverviewPage: Page = {
  color: orange,
  label: 'AWS CloudFront overview',
  link: '/aws-cloudfront-overview',
  visible: true,
  icon: (props) => <HubIcon color="primary" fontSize={props.size} sx={{ color: props.color }}></HubIcon>,
  tooltip: 'Overview of all AWS CloudFront distributions',
  description: 'Total number of AWS CloudFront distributions',
  subDescription: undefined,
  errorMessage: 'Failed to load CloudFront data'
};

function getLoginStatisticsOverviewPage(accountInfo: AccountInfo | null): Page {
  return {
    color: indigo,
    label: 'Login statistics overview',
    link: '/login-statistics-overview',
    visible: isAdmin(accountInfo),
    icon: (props) => (
      <TransferWithinAStationIcon
        color="primary"
        fontSize={props.size}
        sx={{ color: props.color }}
      ></TransferWithinAStationIcon>
    ),
    tooltip: 'Get an overview of logged in user statistics',
    description: 'User login statistics',
    subDescription: undefined,
    errorMessage: 'Failed to load login statistics data'
  };
}

function getPages(accountInfo: AccountInfo | null): Page[] {
  return [
    AzureLoginOverviewPage,
    CertificateOverviewPage,
    AwsResourceOverviewPage,
    AwsCloudFrontOverviewPage,
    AwsHostedZoneOverviewPage,
    HyperplaneEniOverviewPage,
    AwsAccountOverviewPage,
    AwsCostOverviewPage,
    getLoginStatisticsOverviewPage(accountInfo)
  ];
}

export {
  getPages,
  AzureLoginOverviewPage,
  CertificateOverviewPage,
  AwsResourceOverviewPage,
  AwsHostedZoneOverviewPage,
  AwsCloudFrontOverviewPage,
  HyperplaneEniOverviewPage,
  AwsAccountOverviewPage,
  AwsCostOverviewPage
};

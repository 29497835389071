import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

export type AwsResourceType = {
  accountId: string;
  awsRegion: string;
  resourceId: string;
  arn: string;
  resourceType: string;
  configuration: Record<string, unknown>;
};

export type AwsResourcesPayload = {
  resultCount: number;
  result: AwsResourceType[];
};

function useAwsResources(resourceType: string, accountId?: string, region?: string) {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const awsResourcesQuery = useQuery({
    queryKey: ['awsResources', resourceType, accountId, region],
    queryFn: async () => {
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_READ],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );
      const response = await fetch(
        endpoint.CONFIG_API_ENDPOINT +
          `/resources?resourceType=${resourceType}${accountId ? '&accountId=' + accountId : ''}${
            region ? '&region=' + region : ''
          }`,
        {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
            'x-top-api-version': 'v1'
          }
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }
  });

  return {
    data: awsResourcesQuery.data as AwsResourcesPayload,
    error: awsResourcesQuery.error as Error,
    isLoading: awsResourcesQuery.isLoading,
    isError: awsResourcesQuery.isError,
    isFetching: awsResourcesQuery.isFetching,
    isSuccess: awsResourcesQuery.isSuccess,
    dataUpdatedAt: awsResourcesQuery.dataUpdatedAt
  };
}

export default useAwsResources;

import { Grid } from '@mui/material';
import { AwsResourceType } from '../../services/useAwsResources';

interface Props {
  resource: AwsResourceType;
}

const AwsCloudFrontDetails: React.FC<Props> = (props) => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={3}>
        <b>Object:</b> <pre>{JSON.stringify(props.resource, null, 2)}</pre>
      </Grid>
    </Grid>
  );
};

export default AwsCloudFrontDetails;

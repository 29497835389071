import React from 'react';
import { TableCell, Tooltip } from '@mui/material';
import { BaseColumnDefinition, Colorable } from '../types';
import { Cell, Identible, Linkable, Suffixable, Prefixable, Tooltipable } from '../types';

export interface NumberCell extends Cell<number>, Identible, Tooltipable, Suffixable, Prefixable, Linkable, Colorable {}

function renderNumberCell(cell: NumberCell) {
  function renderCellContent(cell: NumberCell) {
    let value = `${cell.value}`;
    if (cell.prefix) {
      value = `${cell.prefix}${value}`;
    }
    if (cell.suffix) {
      value = `${value}${cell.suffix}`;
    }
    if (cell.href) {
      return (
        <a href={cell.href} target={cell.target ?? '_blank'} rel="noreferrer">
          {value}
        </a>
      );
    } else {
      return (
        <span style={{ color: cell.textColor ?? 'inherit', backgroundColor: cell.backgroundColor ?? 'inherit' }}>
          {value}
        </span>
      );
    }
  }

  return (
    <TableCell key={cell.id} align={NumberColumnDefinition.align}>
      {cell.tooltip ? <Tooltip title={cell.tooltip}>{renderCellContent(cell)}</Tooltip> : renderCellContent(cell)}
    </TableCell>
  );
}

function numberComparator<RowType>(sortBy: keyof RowType) {
  return (a: RowType, b: RowType) => {
    const aObj = a[sortBy] as unknown as { value: number };
    const bObj = b[sortBy] as unknown as { value: number };
    if (aObj.value < bObj.value) {
      return -1;
    }
    if (aObj.value > bObj.value) {
      return 1;
    }
    return 0;
  };
}

function numberFilterFn(cell: Cell<unknown>) {
  return (filterValue: string): boolean => {
    const numberCell = cell as NumberCell;
    const searchString = numberCell.suffix ? `${numberCell.value}${numberCell.suffix}` : numberCell.value.toString();
    return searchString.includes(filterValue);
  };
}

export const NumberColumnDefinition: BaseColumnDefinition = {
  sortable: true,
  filterable: true,
  align: 'right',
  width: '1%',
  render: renderNumberCell,
  comparator: numberComparator,
  filterFn: numberFilterFn
};

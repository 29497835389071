import { AccountInfo } from '@azure/msal-browser';

type Claims = {
  roles: string[];
};

export const isAdmin = (account: AccountInfo | null) => {
  if (!account) {
    return false;
  }
  const claims = account.idTokenClaims as Claims;
  if (claims) {
    if (claims['roles']) {
      const roles = claims['roles'] as string[];
      if (roles.includes('Overview.Admin')) {
        return true;
      }
    }
  }
  return false;
};

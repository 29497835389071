import React from 'react';
import { HyperplanesPayload } from '../../services/useHyperplanes';
import { Header, HeaderGroup, Identible } from '../../components/tablev2/types';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { ICTable } from '../../components/tablev2/ic-table';
import { hash } from '../../hashing';

export type AccountData = {
  accountName: string;
  accountNumber: string;
  noOfUsedHyperplanes: number;
  hyperplanes: HyperplanesPayload[];
};

interface Props {
  data: HyperplanesPayload[];
}

interface RowData extends Identible {
  status: StringCell;
  privateIp: StringCell;
  securityGroupId: StringCell;
  subnetId: StringCell;
  availabilityZone: StringCell;
  description: StringCell;
}

const headers: Header<RowData>[] = [
  {
    id: 'HeaderGroup1Header1Details',
    value: 'Status',
    rowType: 'status',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header2Details',
    value: 'Private IP',
    rowType: 'privateIp',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header3Details',
    value: 'Security group ID',
    rowType: 'securityGroupId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header4Details',
    value: 'Subnet ID',
    rowType: 'subnetId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header5Details',
    value: 'AZ',
    tooltip: 'Availability zone',
    rowType: 'availabilityZone',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header6Details',
    value: 'Description',
    rowType: 'description',
    columnDefinition: StringColumnDefinition
  }
];

const headerGroups: HeaderGroup<RowData>[] = [
  {
    id: 'HeaderGroup1Details',
    value: 'Hyperplane details',
    align: 'left',
    headers
  }
];

const buildRows = (fromData: HyperplanesPayload[]) => {
  const data: RowData[] = [];
  fromData.forEach((hyperplane) => {
    const hashedId = `id${hash(hyperplane.MacAddress)}`;
    data.push({
      id: hashedId,
      status: { value: hyperplane.Status, id: `status${hashedId}` },
      privateIp: { value: hyperplane.PrivateIpAddress, id: `privateIp${hashedId}` },
      securityGroupId: { value: hyperplane.Groups[0].GroupId, id: `securityGroupId${hashedId}` },
      subnetId: { value: hyperplane.SubnetId, id: `subnetId${hashedId}` },
      availabilityZone: { value: hyperplane.AvailabilityZone, id: `availabilityZone${hashedId}` },
      description: { value: hyperplane.Description, id: `description${hashedId}` }
    });
  });
  return data;
};

const HyperplaneTable: React.FC<Props> = (props) => {
  return (
    <ICTable
      headers={headerGroups}
      rows={buildRows(props.data)}
      orderBy="privateIp"
      order="asc"
      stripedRows={true}
      filterable={true}
    />
  );
};

export default HyperplaneTable;

import React from 'react';
import useAccountsMetadata from '../../services/useAwsAccountsMetadata';
import StatsBox from './stats-box';
import { AwsAccountOverviewPage as page } from '..';
import ErrorBox from './error-box';

const AccountsMetadataStats: React.FC = () => {
  const {
    data: accountsMetadata,
    error: accountsMetadataError,
    isLoading: accountsMetadataIsLoading,
    isError: accountMetadataIsError
  } = useAccountsMetadata();

  if (accountMetadataIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={accountsMetadataError} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StatsBox loading={accountsMetadataIsLoading} page={page}>
        {accountsMetadata?.resultCount}
      </StatsBox>
    </React.Fragment>
  );
};

export default AccountsMetadataStats;

import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

export type OrganisationAccountsPayload = {
  Arn: string;
  Email: string;
  Id: string;
  JoinedMethod: string;
  JoinedTimestamp: string; // "2022-02-03T09:16:22Z"
  Name: string;
  Status: string;
};

function useOrganizationAccounts() {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const organizationAccountsQuery = useQuery({
    queryKey: ['organizationAccounts'],
    queryFn: async () => {
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_READ],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );
      const response = await fetch(endpoint.ORGANISATIONS_API_ENDPOINT + '/accounts', {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          'x-top-api-version': 'v1'
        }
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    staleTime: 1000 * 60 * 60 * 1 // 1 hours
  });

  return {
    data: organizationAccountsQuery.data as OrganisationAccountsPayload[],
    error: organizationAccountsQuery.error as Error,
    isLoading: organizationAccountsQuery.isLoading,
    isError: organizationAccountsQuery.isError,
    isFetching: organizationAccountsQuery.isFetching,
    isSuccess: organizationAccountsQuery.isSuccess,
    dataUpdatedAt: organizationAccountsQuery.dataUpdatedAt
  };
}

export default useOrganizationAccounts;

import React from 'react';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { ICTable } from '../../components/tablev2/ic-table';
import { Header, HeaderGroup, Identible } from '../../components/tablev2/types';
import { hash } from '../../hashing';

interface Props {
  usedBy: string[];
}

interface RowData extends Identible {
  resourceId: StringCell;
}

const headers: Header<RowData>[] = [
  {
    id: 'CommonHeaderGroup1Header1',
    value: 'Resource Id',
    rowType: 'resourceId',
    columnDefinition: StringColumnDefinition
  }
];

const headerGroup: HeaderGroup<RowData>[] = [
  {
    id: 'CommonHeaderGroup1',
    value: 'Used by',
    align: 'left',
    headers
  }
];

const buildRows = (usedBy: string[]): RowData[] => {
  const data: RowData[] = [];
  usedBy.forEach((element) => {
    const hashedId = `id${hash(element)}`;
    const row: RowData = {
      id: element,
      resourceId: { value: element, id: `resourceId${hashedId}` }
    };
    data.push(row);
  });
  return data;
};

export const CommonDetails: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <ICTable headers={headerGroup} rows={buildRows(props.usedBy)} orderBy="resourceId" />
    </React.Fragment>
  );
};

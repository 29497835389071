import React from 'react';
import useAwsResources from '../../services/useAwsResources';
import StatsBox from './stats-box';
import { CertificateOverviewPage as page } from '..';
import ErrorBox from './error-box';

type CertificateProps = {};

const CertificateStats: React.FC<CertificateProps> = () => {
  const {
    data: awsResources,
    error: awsResourcesError,
    isError: awsResourcesIsError,
    isLoading: awsResourcesIsLoading
  } = useAwsResources('AWS::ACM::Certificate');

  if (awsResourcesIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={awsResourcesError} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StatsBox loading={awsResourcesIsLoading} page={page}>
        {awsResources?.resultCount}
      </StatsBox>
    </React.Fragment>
  );
};

export default CertificateStats;

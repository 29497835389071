import { Container, Skeleton, Stack } from '@mui/material';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { Header, Identible } from '../../components/tablev2/types';
import { hash } from '../../hashing';
import useAwsResources, { AwsResourceType } from '../../services/useAwsResources';
import useOrganizationAccounts, { OrganisationAccountsPayload } from '../../services/useOrganizationAccounts';
import Error from '../error';
import AwsHostedZoneDetails from './aws-hosted-zone-details';
import React from 'react';
import { ICTable } from '../../components/tablev2/ic-table';

type AwsHostedZoneConfigurationRecord = {
  HostedZoneTags: [
    {
      key: string;
      value: string;
    }
  ];
  vpcs: string[];
  HostedZoneConfig: Record<string, unknown>;
  Id: string;
  NameServers: string[];
  Name: string;
};

interface RowData extends Identible {
  accountId: StringCell;
  accountName: StringCell;
  hostedZoneId: StringCell;
  domainName: StringCell;
  nameServers: StringCell;
  expandedElement?: React.ReactNode;
}

const headers: Header<RowData>[] = [
  {
    id: 'AwsHostedZoneOverviewHeader1',
    value: 'Account Id',
    rowType: 'accountId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader2',
    value: 'Account Name',
    rowType: 'accountName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader3',
    value: 'Hosted Zone Id',
    rowType: 'hostedZoneId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader4',
    value: 'Domain name',
    rowType: 'domainName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader5',
    value: 'Name servers',
    rowType: 'nameServers',
    tooltip: 'The time before the certificate expires. Hover over the cell value for actual date/time.',
    columnDefinition: { ...StringColumnDefinition, sortable: false }
  }
];

const buildRows = (fromData: AwsResourceType[], accounts: OrganisationAccountsPayload[]): RowData[] => {
  const data: RowData[] = [];

  fromData.forEach((resource) => {
    const account = accounts.find(({ Id }) => Id === resource.accountId);
    const configuration = resource.configuration as AwsHostedZoneConfigurationRecord;
    const hashedId = `id${hash(resource.arn)}`;
    const row: RowData = {
      id: hashedId,
      accountId: { value: resource.accountId, id: `accountId${hashedId}` },
      accountName: { value: account?.Name || 'N/A', id: `accountName${hashedId}` },
      hostedZoneId: { value: configuration.Id, id: `hostedZoneId${hashedId}` },
      domainName: { value: configuration.Name, id: `domainName${hashedId}` },
      nameServers: {
        value: configuration.NameServers ? configuration.NameServers?.join(', ') : '',
        id: `status${hashedId}`
      },
      expandedElement: <AwsHostedZoneDetails resource={resource}></AwsHostedZoneDetails>
    };
    data.push(row);
  });
  return data;
};

function AwsHostedZoneOverview() {
  const {
    data: awsHostedZones,
    error: awsHostedZonesError,
    isError: awsHostedZonesIsError,
    isLoading: awsHostedZonesIsLoading
  } = useAwsResources('AWS::Route53::HostedZone');
  const { data: organisationAccounts, isLoading: organisationAccountsIsLoading } = useOrganizationAccounts();

  if (awsHostedZonesIsError) {
    return (
      <Error
        customMessage="Error while trying to fetch 'AWS::Route53::HostedZone' from AWS"
        errorMessage={awsHostedZonesError.message}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {awsHostedZonesIsLoading || organisationAccountsIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={70}></Skeleton>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        organisationAccounts &&
        awsHostedZones?.result && (
          <React.Fragment>
            <ICTable
              headers={headers}
              rows={buildRows(awsHostedZones.result, organisationAccounts)}
              orderBy="domainName"
              order="asc"
              filterable={true}
              expandable={true}
            />
          </React.Fragment>
        )
      )}
    </Container>
  );
}

export default AwsHostedZoneOverview;

import { PublicClientApplication, AuthenticationResult, Configuration } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useContext } from 'react';
import ApiEndpointCtx from '../../../context/ApiEndpointContext';

type LoginInformation = {
  userName: string;
  userEmail: string;
  userRoles: string[];
  date: string;
};

interface SingleSignOnProps {
  msalConfig: Configuration;
  children?: React.ReactNode;
}

export default function SingleSignOn(props: SingleSignOnProps) {
  const apiEndpoints = useContext(ApiEndpointCtx);
  const msalInstance = new PublicClientApplication(props.msalConfig);
  msalInstance.enableAccountStorageEvents();
  msalInstance.addEventCallback((event) => {
    const payload: AuthenticationResult = event.payload as AuthenticationResult;
    if (payload && payload.account) {
      const account = payload.account;
      if (!msalInstance.getActiveAccount()) {
        const loginInformation: LoginInformation = {
          userName: 'N/A',
          userRoles: ['N/A'],
          userEmail: 'N/A',
          date: new Date().toISOString()
        };
        if (account.idTokenClaims) {
          const claims = account.idTokenClaims as Record<string, unknown>;
          loginInformation.userName = claims['name'] as string;
          loginInformation.userRoles = claims['roles'] as string[];
          loginInformation.userEmail = claims['preferred_username'] as string;
        }
        const options: Record<string, unknown> = {
          method: 'POST',
          headers: {
            authorization: `Bearer ${payload.accessToken}`,
            'x-top-api-version': 'v1'
          },
          body: JSON.stringify(loginInformation)
        };
        fetch(apiEndpoints?.STATISTICS_API_ENDPOINT + '/login', options)
          .then(() => {
            console.info('successful login');
          })
          .catch((error) => {
            console.error('error', error);
          });
        msalInstance.setActiveAccount(account);
      }
    }
  });

  return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
}

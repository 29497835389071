import React, { useRef } from 'react';
import { Container, Grid, Skeleton, Stack, Tooltip } from '@mui/material';
import useLoginStatistics from '../../services/useLoginStatistics';
import Error from '../error';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Needs to be present to enable chart.js auto-detection, used when running react in strict mode

const chartDataSet = {
  labels: ['labels'],
  datasets: [
    {
      label: 'Logins',
      data: [65]
    }
  ]
};

const options = {
  plugins: {
    title: {
      display: true,
      text: 'Logins per user',
      font: {
        size: 20
      }
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

function LoginStatisticsOverview() {
  const { data, error, isError, isLoading, isFetching, dataUpdatedAt } = useLoginStatistics({
    period: { year: '2024' }
  });
  const [chartData, setChartData] = React.useState(chartDataSet);
  const chartRef = useRef(null);

  React.useEffect(() => {
    if (data) {
      // order logins by date
      const sortedLogins = data.result.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      // get first and last date
      const firstDate = new Date(sortedLogins[0].date);
      const lastDate = new Date(sortedLogins[sortedLogins.length - 1].date);
      lastDate.setHours(23, 59, 59);

      console.log('firstDate', firstDate);
      console.log('lastDate', lastDate);

      // get all dates between first and last date
      const dates: Date[] = [];
      for (let d = firstDate; d <= lastDate; d.setDate(d.getDate() + 1)) {
        dates.push(new Date(d));
      }

      const logins = dates.map((date) => {
        const loginsForDate = sortedLogins.filter((login) => {
          return new Date(login.date).toDateString() === date.toDateString();
        });
        return loginsForDate;
      });

      // find all users that logged in
      const myMap = new Map<string, number[]>();
      sortedLogins.forEach((login) => {
        if (!myMap.has(login.userName)) {
          myMap.set(login.userName, []);
        }
      });

      // add number of logins for each user for each date
      myMap.forEach((value, key) => {
        logins.forEach((loginsForDate) => {
          const loginsForDateAndUser = loginsForDate.filter((login) => {
            return login.userName === key;
          });
          value.push(loginsForDateAndUser.length);
        });
      });

      // create chart data
      const datasets: { label: string; data: number[] }[] = [];
      myMap.forEach((value, key) => {
        datasets.push({ label: key, data: value });
      });

      const chartData = {
        labels: dates.map((date) => {
          return date.toDateString();
        }),
        datasets: datasets
      };

      setChartData(chartData);
    }
  }, [data]);

  if (isError) {
    return <Error customMessage="Error while trying to fetch login statistics" errorMessage={error.message} />;
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {isLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={500}></Skeleton>
        </Stack>
      ) : (
        <div>
          <Bar ref={chartRef} data={chartData} options={options} />
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <span>&nbsp;</span>
            <Tooltip title="Time for when the last recorded cost data was fetch from the server.">
              <span style={{ fontSize: 'small', color: 'grey' }}>
                Last time data was fetched from server:{' '}
                {isFetching ? (
                  <strong>fetching...</strong>
                ) : (
                  <strong>{new Date(dataUpdatedAt).toLocaleTimeString()}</strong>
                )}
              </span>
            </Tooltip>
          </Grid>
        </div>
      )}
    </Container>
  );
}

export default LoginStatisticsOverview;

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { getPages } from '../../pages';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

interface TopbarMenuProps {
  appBarRef: React.RefObject<HTMLDivElement>;
}

function TopbarMenu(props: TopbarMenuProps) {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const handleOpenNavMenu = () => {
    setShow(true);
  };

  const handleCloseNavMenu = () => {
    setShow(false);
  };

  return (
    <div>
      <IconButton
        id="menu-button"
        size="large"
        color="inherit"
        aria-label="open menu"
        aria-controls="menu-topbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-topbar"
        open={show}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleCloseNavMenu}
        anchorEl={props.appBarRef.current}
      >
        {getPages(instance.getActiveAccount()).map(
          (page) =>
            page.visible && (
              <MenuItem
                key={page.link}
                onClick={() => {
                  navigate(page.link);
                  handleCloseNavMenu();
                }}
              >
                {page.icon({ size: 'medium' })}
                <Typography textAlign="center" sx={{ pl: '10px' }}>
                  {page.label}
                </Typography>
              </MenuItem>
            )
        )}
      </Menu>
    </div>
  );
}

export default TopbarMenu;

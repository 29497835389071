import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

export type ResourceType = {
  item: string;
  itemType: string;
  quantity: number;
};

export type CountAwsResourcesPayload = {
  resultCount: number;
  result: ResourceType[];
};

function useCountAwsResources(type: 'account' | 'resource', accountId?: string) {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const countAwsResourcesQuery = useQuery({
    queryKey: ['countAwsResources', type, accountId],
    queryFn: async () => {
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_READ],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );
      const response = await fetch(
        endpoint.CONFIG_API_ENDPOINT + `/count?type=${type}${accountId ? '&accountId=' + accountId : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
            'x-top-api-version': 'v1'
          }
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }
  });

  return {
    data: countAwsResourcesQuery.data as CountAwsResourcesPayload,
    error: countAwsResourcesQuery.error as Error,
    isLoading: countAwsResourcesQuery.isLoading,
    isError: countAwsResourcesQuery.isError,
    isFetching: countAwsResourcesQuery.isFetching,
    isSuccess: countAwsResourcesQuery.isSuccess,
    dataUpdatedAt: countAwsResourcesQuery.dataUpdatedAt
  };
}

export default useCountAwsResources;

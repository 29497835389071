import { Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton, Stack } from '@mui/material';
import React from 'react';
import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { Header, HeaderGroup } from '../../components/tablev2/types';
import { ICTable } from '../../components/tablev2/ic-table';
import { Identible } from '../../components/tablev2/types';
import useAzureGraphApi, { LoginSummary } from '../../services/useAzureGraphApi';
import Error from '../error';
import { hash } from '../../hashing';

interface RowData extends Identible {
  appDisplayName: StringCell;
  successfulSignInCount: NumberCell;
  failedSignInCount: NumberCell;
  successPercentage: NumberCell;
}

const headers: Header<RowData>[] = [
  {
    id: 'AzureHeader1',
    value: 'Single signon name',
    rowType: 'appDisplayName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AzureHeader2',
    value: 'Successful signins',
    rowType: 'successfulSignInCount',
    columnDefinition: NumberColumnDefinition
  },
  {
    id: 'AzureHeader3',
    value: 'Failed signins',
    rowType: 'failedSignInCount',
    columnDefinition: NumberColumnDefinition
  },
  {
    id: 'AzureHeader4',
    value: 'Success %',
    rowType: 'successPercentage',
    columnDefinition: NumberColumnDefinition
  }
];

const headerGroups: HeaderGroup<RowData>[] = [
  {
    id: 'AzureHeaderGroup1',
    value: 'Azure AD Sign-in Overview',
    align: 'left',
    headers
  }
];

const buildRows = (fromData: LoginSummary[]): RowData[] => {
  const data: RowData[] = [];
  fromData.forEach((type) => {
    const hashedId = `id${hash(type.appDisplayName + type.successfulSignInCount + type.failedSignInCount)}`;
    data.push({
      id: hashedId,
      appDisplayName: { value: type.appDisplayName, id: `displayName${hashedId}` },
      successfulSignInCount: {
        value: type.successfulSignInCount,
        id: `successfulSignInCount${hashedId}`
      },
      failedSignInCount: { value: type.failedSignInCount, id: `failedSignInCount${hashedId}` },
      successPercentage: {
        value: type.successPercentage,
        id: `successPercentage${hashedId}`,
        suffix: '%'
      }
    });
  });
  return data;
};

function AzureLoginOverview() {
  const [showLast, setShowLast] = React.useState<'D7' | 'D30'>('D30');
  const {
    data: azureLogins,
    error: azureLoginsError,
    isError: azureLoginsIsError,
    isLoading: azureLoginsIsLoading
  } = useAzureGraphApi(showLast);

  if (azureLoginsIsError) {
    return <Error customMessage="Error while trying to fetch Azure logins" errorMessage={azureLoginsError.message} />;
  }

  const handleChangeShowLast = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowLast(event.target.value as 'D7' | 'D30');
  };

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {azureLoginsIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={70}></Skeleton>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        azureLogins?.loginSummary && (
          <React.Fragment>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Show last: </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={showLast}
                onChange={handleChangeShowLast}
              >
                <FormControlLabel value="D7" control={<Radio />} label="7 days" />
                <FormControlLabel value="D30" control={<Radio />} label="30 days" />
              </RadioGroup>
            </FormControl>
            <ICTable
              headers={headerGroups}
              rows={buildRows(azureLogins.loginSummary)}
              orderBy="successfulSignInCount"
              filterable={true}
              stripedRows={true}
              order="desc"
            />
          </React.Fragment>
        )
      )}
    </Container>
  );
}

export default AzureLoginOverview;

import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { instanceOfHeaderArray, instanceOfHeaderGroupArray } from './type-guards';
import { blueGrey } from '@mui/material/colors';
import { Header, HeaderGroup, SortDirection } from './types';

function CellValue({ value, tooltip }: { value: string; tooltip?: string }) {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
    </Tooltip>
  ) : (
    <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
  );
}

function HeaderGroupCell<RowType>({
  headerGroup,
  first,
  expandable
}: {
  headerGroup: HeaderGroup<RowType>;
  first: boolean;
  expandable: boolean;
}) {
  function calculateColSpan() {
    return first ? headerGroup.headers.length + (expandable ? 1 : 0) : headerGroup.headers.length;
  }

  return (
    <TableCell
      sx={{
        backgroundColor: headerGroup.backgroundColor ?? blueGrey[400],
        color: headerGroup.fontColor ?? 'black',
        borderLeft: first ? 'none' : `1px solid ${blueGrey[200]}`
      }}
      colSpan={calculateColSpan()}
      key={headerGroup.id}
      align={headerGroup.align ?? 'center'}
    >
      <CellValue value={headerGroup.value} tooltip={headerGroup.tooltip} />
    </TableCell>
  );
}

function HeaderCell<RowType>({
  header,
  first,
  sortBy,
  sortDirection,
  setSortBy,
  setSortDirection
}: {
  header: Header<RowType>;
  first: boolean;
  sortBy: keyof RowType;
  sortDirection: SortDirection;
  setSortBy: React.Dispatch<React.SetStateAction<keyof RowType>>;
  setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
}) {
  function handleSort(rowType: keyof RowType) {
    const isAsc = sortBy === rowType && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(rowType);
  }

  return (
    <TableCell
      sx={{
        backgroundColor: header.backgroundColor ?? blueGrey[300],
        color: header.fontColor ?? 'black',
        borderLeft: first ? 'none' : `1px solid ${blueGrey[200]}`,
        width: header.columnDefinition.width ?? 'auto'
      }}
      key={header.id}
      align={header.align ?? 'left'}
      sortDirection={header.columnDefinition.sortable ? 'asc' : false}
    >
      {header.columnDefinition.sortable ? (
        <TableSortLabel
          active={sortBy === header.rowType}
          direction={sortDirection}
          onClick={() => handleSort(header.rowType)}
        >
          <CellValue value={header.value} tooltip={header.tooltip} />
        </TableSortLabel>
      ) : (
        <CellValue value={header.value} tooltip={header.tooltip} />
      )}
    </TableCell>
  );
}

export function renderTableHeaders<RowType>(
  headers: HeaderGroup<RowType>[] | Header<RowType>[],
  sortBy: keyof RowType,
  sortDirection: SortDirection,
  setSortBy: React.Dispatch<React.SetStateAction<keyof RowType>>,
  setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>,
  expandable: boolean
) {
  return (
    <TableHead>
      {instanceOfHeaderGroupArray(headers) && (
        <React.Fragment>
          <TableRow>
            {headers.map((headerGroup, index) => {
              if (index === 0) {
                return (
                  <HeaderGroupCell
                    key={headerGroup.id}
                    headerGroup={headerGroup}
                    first={true}
                    expandable={expandable}
                  />
                );
              } else {
                return (
                  <HeaderGroupCell
                    key={headerGroup.id}
                    headerGroup={headerGroup}
                    first={false}
                    expandable={expandable}
                  />
                );
              }
            })}
          </TableRow>
          <TableRow>
            {expandable && <TableCell sx={{ backgroundColor: blueGrey[300], width: '1%' }}></TableCell>}
            {headers.map((headerGroup, index) => {
              if (index === 0) {
                return headerGroup.headers.map((header, index) => {
                  if (index === 0) {
                    return (
                      <HeaderCell
                        key={header.id}
                        header={header}
                        first={true}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        setSortBy={setSortBy}
                        setSortDirection={setSortDirection}
                      />
                    );
                  } else {
                    return (
                      <HeaderCell
                        key={header.id}
                        header={header}
                        first={false}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        setSortBy={setSortBy}
                        setSortDirection={setSortDirection}
                      />
                    );
                  }
                });
              } else {
                return headerGroup.headers.map((header) => {
                  return (
                    <HeaderCell
                      key={header.id}
                      header={header}
                      first={false}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      setSortBy={setSortBy}
                      setSortDirection={setSortDirection}
                    />
                  );
                });
              }
            })}
          </TableRow>
        </React.Fragment>
      )}
      {instanceOfHeaderArray(headers) && (
        <TableRow>
          {expandable && <TableCell sx={{ backgroundColor: blueGrey[300], width: '1%' }}></TableCell>}
          {headers.map((header, index) => {
            if (index === 0) {
              return (
                <HeaderCell
                  key={header.id}
                  header={header}
                  first={true}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  setSortBy={setSortBy}
                  setSortDirection={setSortDirection}
                />
              );
            } else {
              return (
                <HeaderCell
                  key={header.id}
                  header={header}
                  first={false}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  setSortBy={setSortBy}
                  setSortDirection={setSortDirection}
                />
              );
            }
          })}
        </TableRow>
      )}
    </TableHead>
  );
}

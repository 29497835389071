import React from 'react';
import { Outlet } from 'react-router-dom';
import Topbar from './components/topbar';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import ErrorBoundary from './error-boundary';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import LoginInProcess from './components/login/process';
import ApplicationScopesCtx from './context/ApplicationScopesContext';

interface InitializeLoginProps {
  domainHint: string;
}

function InitializeLogin(props: InitializeLoginProps) {
  const applicationScopes = React.useContext(ApplicationScopesCtx);
  const { login, error } = useMsalAuthentication(InteractionType.Silent, {
    scopes: [applicationScopes!.OVERVIEW_READ],
    domainHint: props.domainHint
  });

  if (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.warn('The first login attemt failed, trying again with a redirect');
      try {
        login(InteractionType.Redirect);
      } catch (error) {
        console.error('Login also failed with redirect', error);
        console.info('No login possible!!');
        // Maybe return an error page here
      }
    }
  }

  return (
    <React.Fragment>
      <ErrorBoundary>
        <AuthenticatedTemplate>
          <Topbar />
          <Outlet />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginInProcess />
        </UnauthenticatedTemplate>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default InitializeLogin;

import React from 'react';
import { IconButton, styled, TableBody, TableCell, TableRow } from '@mui/material';
import { Header } from './types';
import { Identible } from './types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

function Row<RowType extends Identible>(props: {
  rowData: RowType;
  headers: Header<RowType>[];
  stripedRows: boolean;
  expandable: boolean;
  expandableBackgroundColor: string;
}) {
  const [open, setOpen] = React.useState(false);

  function renderCells() {
    return props.headers.map((header) => {
      const rowObj = props.rowData[header.rowType];
      return header.columnDefinition.render(rowObj, header.columnDefinition);
    });
  }

  function renderExpandCell() {
    if (props.expandable) {
      return (
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      );
    }
  }

  function renderExpandedElement() {
    if (open) {
      const row = props.rowData as unknown as { expandedElement: React.ReactNode };
      return (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 10, paddingTop: 0, backgroundColor: props.expandableBackgroundColor }}
            colSpan={props.headers.length + 1}
          >
            {row.expandedElement}
          </TableCell>
        </TableRow>
      );
    }
  }

  function renderRow() {
    if (props.stripedRows) {
      return (
        <React.Fragment>
          <StyledTableRow key={props.rowData.id}>
            {renderExpandCell()}
            {renderCells()}
          </StyledTableRow>
          {renderExpandedElement()}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <TableRow key={props.rowData.id}>
            {renderExpandCell()}
            {renderCells()}
          </TableRow>
          {renderExpandedElement()}
        </React.Fragment>
      );
    }
  }

  return renderRow();
}

export function renderTableBody<RowType extends Identible>(
  headers: Header<RowType>[],
  rows: RowType[],
  stripedRows: boolean,
  expandable: boolean,
  expandableBackgroundColor: string
) {
  return (
    <TableBody>
      {rows.map((row) => {
        return (
          <Row
            key={row.id}
            rowData={row}
            headers={headers}
            stripedRows={stripedRows}
            expandable={expandable}
            expandableBackgroundColor={expandableBackgroundColor}
          />
        );
      })}
    </TableBody>
  );
}

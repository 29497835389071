import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HyperplaneOverview from './pages/hyperplane-overview';
import Welcome from './pages/welcome';
import { Configuration } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import InitializeLogin from './initialize-login';
import AwsResourceOverview from './pages/aws-resource-overview';
import CertificateOverview from './pages/certificate-overview';
import AzureLoginOverview from './pages/azure-login-overview';
import AwsAccountOverview from './pages/aws-account-overview';
import SingleSignOn from './components/login/sso/index';
import LoginStatisticsOverview from './pages/login-statistics-overview';
import AwsCostOverview from './pages/aws-cost-overview';
import AwsHostedZoneOverview from './pages/aws-hosted-zone-overview';
import AwsCloudFrontOverview from './pages/aws-cloudfront-overview';

interface AppProps {
  msalConfig: Configuration;
  domainHint: string;
}

function App(props: AppProps) {
  return (
    <React.Fragment>
      <SingleSignOn msalConfig={props.msalConfig}>
        {process.env.REACT_APP_MAINTENANCE === 'api' && (
          <div
            style={{
              backgroundColor: 'red',
              color: 'white',
              padding: '1rem',
              textAlign: 'center'
            }}
          >
            InCloud Overview is currently under backend API maintenance. Data loading fails might occur, we will be up
            shortly.
          </div>
        )}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<InitializeLogin domainHint={props.domainHint} />}>
              <Route path="/hyperplane-overview" element={<HyperplaneOverview />} />
              <Route path="/aws-resource-overview" element={<AwsResourceOverview />} />
              <Route path="/certificate-overview" element={<CertificateOverview />} />
              <Route path="/azure-logins-overview" element={<AzureLoginOverview />} />
              <Route path="/aws-cloudfront-overview" element={<AwsCloudFrontOverview />} />
              <Route path="/aws-account-overview" element={<AwsAccountOverview />} />
              <Route path="/aws-hosted-zone-overview" element={<AwsHostedZoneOverview />} />
              <Route path="/login-statistics-overview" element={<LoginStatisticsOverview />} />
              <Route path="/aws-cost-overview" element={<AwsCostOverview />} />
              <Route path="/" element={<Welcome />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: '1rem' }}>
                    <p>There&#39;s nothing here!</p>
                    <p>The requested url does not exists in the overview application</p>
                  </main>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </SingleSignOn>
    </React.Fragment>
  );
}

export default App;

import React from 'react';
import useAwsResources, { AwsResourceType } from '../../services/useAwsResources';
import { Header, Identible } from '../../components/tablev2/types';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import useOrganizationAccounts, { OrganisationAccountsPayload } from '../../services/useOrganizationAccounts';
import { hash } from '../../hashing';
import AwsCloudFrontDetails from './aws-cloudfront-details';
import Error from '../error';
import { Container, Skeleton, Stack } from '@mui/material';
import { ICTable } from '../../components/tablev2/ic-table';

type DefaultCacheBehavior = {};
type Aliases = {
  quantity: number;
  items: string[];
};
type OriginItem = {
  s3OriginConfig: {
    originAccessIdentity: string;
  };
  originShield: {
    enabled: boolean;
  };
  originAccessControlId: string;
  originPath: string;
  connectionAttempts: number;
  domainName: string;
  id: string;
  connectionTimeout: number;
  customHeaders: {
    quantity: number;
  };
};

type Origins = {
  quantity: number;
  items: OriginItem[];
};

type DistributionConfig = {
  defaultCacheBehavior: DefaultCacheBehavior;
  aliases: Aliases;
  origins: Origins;
};

type AliasICPRecordals = {};

type ActiveTrustedSigners = {};

type ActiveTrustedKeyGroups = {};

export type AwsCloudFrontConfigurationRecord = {
  lastModifiedTime: string;
  distributionConfig: DistributionConfig;
  domainName: string;
  aliasICPRecordals: AliasICPRecordals;
  activeTrustedSigners: ActiveTrustedSigners;
  id: string;
  activeTrustedKeyGroups: ActiveTrustedKeyGroups;
  arn: string;
};

interface RowData extends Identible {
  accountId: StringCell;
  accountName: StringCell;
  resourceId: StringCell;
  alias: StringCell;
  domainName: StringCell;
  originDomains: StringCell;
  expandedElement?: React.ReactNode;
}

const headers: Header<RowData>[] = [
  {
    id: 'AwsHostedZoneOverviewHeader1',
    value: 'Account Id',
    rowType: 'accountId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader2',
    value: 'Account Name',
    rowType: 'accountName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader3',
    value: 'Resource Id',
    rowType: 'resourceId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader4',
    value: 'Alias',
    rowType: 'alias',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader5',
    value: 'Domain Name',
    rowType: 'domainName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AwsHostedZoneOverviewHeader6',
    value: 'Origin Domains',
    rowType: 'originDomains',
    columnDefinition: StringColumnDefinition
  }
];

const buildRows = (fromData: AwsResourceType[], accounts: OrganisationAccountsPayload[]): RowData[] => {
  const data: RowData[] = [];

  fromData.forEach((resource) => {
    const account = accounts.find(({ Id }) => Id === resource.accountId);
    const configuration = resource.configuration as AwsCloudFrontConfigurationRecord;
    const hashedId = `id${hash(resource.arn)}`;
    const allOriginDomains = configuration.distributionConfig.origins.items
      .map((origin) => origin.domainName)
      .join(', ');
    const row: RowData = {
      id: hashedId,
      accountId: { value: resource.accountId, id: `accountId${hashedId}` },
      accountName: { value: account?.Name || 'N/A', id: `accountName${hashedId}` },
      resourceId: { value: resource.resourceId, id: `resourceId${hashedId}` },
      alias: { value: configuration.distributionConfig.aliases.items?.join(', '), id: `alias${hashedId}` },
      domainName: { value: configuration.domainName, id: `domainName${hashedId}` },
      originDomains: { value: allOriginDomains, id: `originDomain${hashedId}` },
      expandedElement: <AwsCloudFrontDetails resource={resource}></AwsCloudFrontDetails>
    };
    data.push(row);
  });
  return data;
};

function AwsCloudFrontOverview() {
  const {
    data: awsCloudFronts,
    error: awsCloudFrontsError,
    isError: awsCloudFrontsIsError,
    isLoading: awsCloudFrontsIsLoading
  } = useAwsResources('AWS::CloudFront::Distribution');
  const { data: organisationAccounts, isLoading: organisationAccountsIsLoading } = useOrganizationAccounts();

  if (awsCloudFrontsIsError) {
    return (
      <Error
        customMessage="Error while trying to fetch 'AWS::Route53::HostedZone' from AWS"
        errorMessage={awsCloudFrontsError.message}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {awsCloudFrontsIsLoading || organisationAccountsIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={70}></Skeleton>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        organisationAccounts &&
        awsCloudFronts?.result && (
          <React.Fragment>
            <ICTable
              headers={headers}
              rows={buildRows(awsCloudFronts.result, organisationAccounts)}
              orderBy="accountName"
              order="asc"
              filterable={true}
              expandable={true}
            />
          </React.Fragment>
        )
      )}
    </Container>
  );
}

export default AwsCloudFrontOverview;

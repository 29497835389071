import React from 'react';
import App from './App';
import ErrorBoundary from './error-boundary';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Configuration } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import getApiConfig, { ApiEndpoint } from './api-config';
import ApiEndpointCtx from './context/ApiEndpointContext';
import ApplicationScopesCtx from './context/ApplicationScopesContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

let config: Configuration;
let endpoints: ApiEndpoint;
let domainHint: string;
let scopes: string[]; // the available scopes for the application
if (!process.env.REACT_APP_ORG) {
  throw new Error('Error initializing application, could not find process.env.REACT_APP_ORG');
}

if (process.env.REACT_APP_STAGE === 'production') {
  console.info(`loading production:${process.env.REACT_APP_ORG} auth config...`);
  config = msalConfig.production;
  endpoints = getApiConfig('production', process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4');
  domainHint = 'topdanmark.dk';
  scopes = [
    config.auth.clientId + '/InCloudOverview.Read',
    config.auth.clientId + '/InCloudOverview.Write',
    config.auth.clientId + '/InCloudOverview.Admin'
  ];
} else if (process.env.REACT_APP_STAGE === 'staging') {
  console.info(`loading staging:${process.env.REACT_APP_ORG} auth config...`);
  config = msalConfig.staging;
  endpoints = getApiConfig('staging', process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4');
  domainHint = 'top366.dk';
  scopes = [
    config.auth.clientId + '/InCloudOverview.Read',
    config.auth.clientId + '/InCloudOverview.Write',
    config.auth.clientId + '/InCloudOverview.Admin'
  ];
} else {
  throw new Error(
    `Error initializing application, could not find process.env.REACT_APP_STAGE ${JSON.stringify(process.env)}`
  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <ApplicationScopesCtx.Provider
      value={{ OVERVIEW_READ: scopes[0], OVERVIEW_WRITE: scopes[1], OVERVIEW_ADMIN: scopes[2] }}
    >
      <ApiEndpointCtx.Provider value={{ ...endpoints }}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <App msalConfig={config} domainHint={domainHint} />
            <ReactQueryDevtools initialIsOpen={false} />
          </ErrorBoundary>
        </QueryClientProvider>
      </ApiEndpointCtx.Provider>
    </ApplicationScopesCtx.Provider>
  </React.StrictMode>
);

reportWebVitals();

import { Container, FormControl, FormControlLabel, Skeleton, Stack, Switch } from '@mui/material';
import React from 'react';
import { BooleanCell, BooleanColumnDefinition } from '../../components/tablev2/cells/boolean-cell';
import { DateCell, DateColumnDefinition } from '../../components/tablev2/cells/date-cell';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { Header, ImagableColumnDefinition } from '../../components/tablev2/types';
import { ICTable } from '../../components/tablev2/ic-table';
import { Identible } from '../../components/tablev2/types';
import useAwsResources, { AwsResourceType } from '../../services/useAwsResources';
import useOrganizationAccounts, { OrganisationAccountsPayload } from '../../services/useOrganizationAccounts';
import Error from '../error';
import { CertificateDetails } from './certificate-details';
import { hash } from '../../hashing';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDownAlt';
import { green, red } from '@mui/material/colors';
import moment from 'moment';

type CertificateRecord = {
  createdAt: string;
  domainName: string;
  issuer: string;
  inUseBy: Array<string>;
  notAfter: string;
  notBefore: string;
  issuedAt: string;
  renewalEligibility: string;
  status: string;
};

interface RowData extends Identible {
  accountId: StringCell;
  accountName: StringCell;
  domainName: StringCell;
  status: StringCell;
  expires: DateCell;
  inUse: BooleanCell;
  renewal: StringCell;
  expandedElement?: React.ReactNode;
}

const headers: Header<RowData>[] = [
  {
    id: 'CertificateOverviewHeader1',
    value: 'Account Id',
    rowType: 'accountId',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CertificateOverviewHeader2',
    value: 'Account Name',
    rowType: 'accountName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CertificateOverviewHeader3',
    value: 'Domain Name',
    rowType: 'domainName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CertificateOverviewHeader4',
    value: 'Status',
    rowType: 'status',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CertificateOverviewHeader5',
    value: 'Expire',
    rowType: 'expires',
    tooltip: 'The time before the certificate expires. Hover over the cell value for actual date/time.',
    columnDefinition: DateColumnDefinition
  },
  {
    id: 'CertificateOverviewHeader6',
    value: 'In use',
    rowType: 'inUse',
    tooltip:
      'Specifies if the certificate is in use by any AWS resources. Expand the row to see what resources are using this certificate.',
    columnDefinition: {
      ...BooleanColumnDefinition,
      imageMap: {
        true: <ThumbUpAltIcon sx={{ color: green[500] }} />,
        false: <ThumbDownIcon sx={{ color: red[500] }} />
      }
    } as ImagableColumnDefinition
  },
  {
    id: 'CertificateOverviewHeader7',
    value: 'Renewal',
    rowType: 'renewal',
    tooltip: "The certificate's renewal eligibility, which is one of the following: Auto / Manual.",
    columnDefinition: StringColumnDefinition
  }
];

const buildRows = (
  fromData: AwsResourceType[],
  accounts: OrganisationAccountsPayload[],
  filterByOnlyInUse: boolean
): RowData[] => {
  const data: RowData[] = [];

  fromData
    .filter((resource) => {
      if (filterByOnlyInUse) {
        const configuration = resource.configuration as CertificateRecord;
        return configuration.inUseBy.length > 0;
      }
      return true;
    })
    .forEach((resource) => {
      const account = accounts.find(({ Id }) => Id === resource.accountId);
      const configuration = resource.configuration as CertificateRecord;
      const hashedId = `id${hash(resource.arn)}`;
      const row: RowData = {
        id: hashedId,
        accountId: { value: resource.accountId, id: `accountId${hashedId}` },
        accountName: { value: account?.Name || 'N/A', id: `accountName${hashedId}` },
        domainName: { value: configuration.domainName, id: `domainName${hashedId}` },
        status: { value: configuration.status, id: `status${hashedId}` },
        expires: {
          value: new Date(configuration.notAfter),
          id: `expires${hashedId}`,
          tooltip: `${moment(new Date(configuration.notAfter)).format('LLL')}`
        },
        inUse: { value: configuration.inUseBy.length > 0 ? true : false, id: `inUse${hashedId}` },
        renewal: {
          value: configuration.renewalEligibility === 'ELIGIBLE' ? 'Auto' : 'Manual',
          id: `renewal${hashedId}`,
          tooltip:
            configuration.renewalEligibility === 'ELIGIBLE'
              ? 'Auto means that AWS will update this certificate'
              : 'Manual means that you will have to update this certificate'
        },
        expandedElement: (
          <CertificateDetails
            activeFrom={configuration.notBefore}
            createdAt={configuration.createdAt}
            issueDate={configuration.issuedAt}
            issuer={configuration.issuer}
            status={configuration.status}
            usedBy={configuration.inUseBy}
            accountId={resource.accountId}
            configuration={configuration}
          ></CertificateDetails>
        )
      };
      data.push(row);
    });
  return data;
};

function CertificateOverview() {
  const {
    data: awsResources,
    error: awsResourcesError,
    isError: awsResourcesIsError,
    isLoading: awsResourcesIsLoading
  } = useAwsResources('AWS::ACM::Certificate');
  const { data: organisationAccounts, isLoading: organisationAccountsIsLoading } = useOrganizationAccounts();
  const [onlyInUse, setOnlyInUse] = React.useState(true);

  if (awsResourcesIsError) {
    return (
      <Error
        customMessage="Error while trying to fetch 'AWS::ACM::Certificate' from AWS"
        errorMessage={awsResourcesError.message}
      />
    );
  }

  const handleChangeOnlyInUse = (value: boolean): void => {
    setOnlyInUse(!value);
  };

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {awsResourcesIsLoading || organisationAccountsIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={70}></Skeleton>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        organisationAccounts &&
        awsResources?.result && (
          <React.Fragment>
            <FormControl>
              <FormControlLabel
                control={<Switch checked={onlyInUse} onChange={() => handleChangeOnlyInUse(onlyInUse)} />}
                label="Show used certificates only"
              />
            </FormControl>
            <ICTable
              headers={headers}
              rows={buildRows(awsResources.result, organisationAccounts, onlyInUse)}
              orderBy="expires"
              filterable={true}
              expandable={true}
            />
          </React.Fragment>
        )
      )}
    </Container>
  );
}

export default CertificateOverview;

import React from 'react';
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Tooltip
} from '@mui/material';
import useCostForAccount from '../../services/useAwsCostForAccount';
import Error from '../error';
import { Header, HeaderGroup, Identible } from '../../components/tablev2/types';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { ICTable } from '../../components/tablev2/ic-table';
import { AwsAccountCost } from '../../services/useAwsLastestCosts';
import { hash } from '../../hashing';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import useAccountsMetadata from '../../services/useAwsAccountsMetadata';

export interface RowData extends Identible {
  accountCostDate: StringCell;
  accountCost: NumberCell;
  accountCostChange: NumberCell;
  accountCostChangePercentage: NumberCell;
}

const headers: Header<RowData>[] = [
  {
    id: 'CostHeader1',
    value: 'Cost Date',
    tooltip: 'The last date we have cost data for. Hover over the value to see the exact date.',
    rowType: 'accountCostDate',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CostHeader2',
    value: 'Cost',
    tooltip: 'The cost for the given date including taxes',
    rowType: 'accountCost',
    columnDefinition: NumberColumnDefinition
  },
  {
    id: 'CostHeader3',
    value: 'Change',
    tooltip: 'The change in cost from the previous day',
    rowType: 'accountCostChange',
    columnDefinition: NumberColumnDefinition
  },
  {
    id: 'CostHeader4',
    value: 'Percentage',
    tooltip: 'The percentage change in cost from the previous day',
    rowType: 'accountCostChangePercentage',
    columnDefinition: NumberColumnDefinition
  }
];

function generateHeaderGroups(accountId: string, accountName: string): HeaderGroup<RowData>[] {
  return [
    {
      id: 'CostHeaderGroup1',
      value: `AWS Cost Overview for account ${accountName} (${accountId})`,
      align: 'left',
      headers
    }
  ];
}

function buildRows(accountCostData: AwsAccountCost[]) {
  const data: RowData[] = [];
  let lastCost = 0;
  accountCostData.forEach((cost) => {
    const hashedId = `id${hash(cost.accountId + cost.costDate + cost.cost)}`;
    const change = Number(cost.cost) - lastCost;
    lastCost = Number(cost.cost);
    data.push({
      id: hashedId,
      accountCostDate: {
        value: moment(cost.costDate).format('YYYY-MM-DD'),
        tooltip: `Cost data for ${moment(cost.costDate).format('MMMM Do YYYY')}`,
        id: `accountCostDate${hashedId}`
      },
      accountCost: { value: parseFloat(Number(cost.cost).toFixed(2)), id: `accountCost${hashedId}`, prefix: '$' },
      accountCostChange: {
        value: parseFloat(Number(change).toFixed(2)),
        id: `accountChange${hashedId}`,
        tooltip: `Change from previous day in $`,
        textColor: change > 0 ? 'red' : 'green',
        backgroundColor: change > 0 ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 255, 0, 0.2)'
      },
      accountCostChangePercentage: {
        value: parseFloat(((change / lastCost) * 100).toFixed(2)),
        id: `accountChangePercentage${hashedId}`,
        suffix: '%',
        tooltip: `Change from previous day in %`,
        textColor: change > 0 ? 'red' : 'green',
        backgroundColor: change > 0 ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 255, 0, 0.2)'
      }
    });
  });
  return data;
}

function SpecificAccountCosts({ accountId }: { accountId: string }) {
  const [graphDisplay, setGraphDisplay] = React.useState<'all' | 'thisYear' | 'last30Days' | 'last7Days'>('last30Days');
  const {
    data: accountCostData,
    error: accountCostError,
    isLoading: accountCostIsLoading,
    isError: accountCostIsError,
    dataUpdatedAt: accountCostUpdatedAt
  } = useCostForAccount(accountId);

  const { data: accountsMetadataData, isLoading: accountMetadataIsLoading } = useAccountsMetadata();

  if (accountCostIsError) {
    return (
      <Error
        customMessage={`Error while trying to fetch all cost data for account ${accountId}`}
        errorMessage={accountCostError.message}
      />
    );
  }

  function transformData() {
    const data = accountCostData?.result || [];

    if (data.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            label: 'Cost',
            data: [],
            backgroundColor: [],
            borderColor: [],
            fill: true,
            borderWidth: 1,
            pointRadius: 0
          }
        ]
      };
    }
    const thisYear = moment().startOf('year').format('YYYY-MM-DD');
    const last30Days = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const last7Days = moment().subtract(7, 'days').format('YYYY-MM-DD');
    const displayData = data.filter((costData) => {
      switch (graphDisplay) {
        case 'thisYear':
          return moment(costData.costDate) >= moment(thisYear);
        case 'last30Days':
          return moment(costData.costDate) >= moment(last30Days);
        case 'last7Days':
          return moment(costData.costDate) >= moment(last7Days);
        default:
          return true;
      }
    });
    return {
      labels: displayData.map((costData) => costData.costDate),
      datasets: [
        {
          label: 'Cost',
          data: displayData.map((cost) => parseFloat(Number(cost.cost).toFixed(2))),
          backgroundColor: displayData.map(() => 'rgba(54, 162, 235, 0.2)'),
          borderColor: displayData.map(() => 'rgba(54, 162, 235, 1)'),
          fill: true,
          borderWidth: 1,
          pointRadius: 0
        }
      ]
    };
  }

  const handleChangeGraphDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraphDisplay(event.target.value as 'all' | 'thisYear' | 'last30Days' | 'last7Days');
  };

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {accountCostIsLoading || accountMetadataIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={200}></Skeleton>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        accountCostData && (
          <React.Fragment>
            <div>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Graph display: </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={graphDisplay}
                  onChange={handleChangeGraphDisplay}
                >
                  <FormControlLabel value="all" control={<Radio />} label="All registered days" />
                  <FormControlLabel value="thisYear" control={<Radio />} label="This year" />
                  <FormControlLabel value="last30Days" control={<Radio />} label="last 30 days" />
                  <FormControlLabel value="last7Days" control={<Radio />} label="last 7 days" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <Line data={transformData()} options={{ maintainAspectRatio: false, responsive: true }} height={200} />
            </div>
            <ICTable
              rows={buildRows(accountCostData?.result || [])}
              headers={generateHeaderGroups(
                accountId,
                accountsMetadataData.result.find((meta) => meta.accountId === accountId)?.accountName || ''
              )}
              orderBy={'accountCostDate'}
              order={'desc'}
              filterable={true}
              stripedRows={true}
            />
            <Grid container justifyContent="space-between" alignItems="flex-end">
              <span>&nbsp;</span>
              <Tooltip title="Time for when the last recorded cost data was fetch from the server. Cost data is updated in the backend once a day.">
                <span style={{ fontSize: 'small', color: 'grey' }}>
                  Last time data was fetched from server:{' '}
                  {accountCostIsLoading ? (
                    <strong>fetching...</strong>
                  ) : (
                    <strong>{new Date(accountCostUpdatedAt).toLocaleTimeString()}</strong>
                  )}
                </span>
              </Tooltip>
            </Grid>
          </React.Fragment>
        )
      )}
    </Container>
  );
}

export default SpecificAccountCosts;

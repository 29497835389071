import { HeaderGroup, Header } from './types';

export function instanceOfHeaderGroupArray<RowType>(object: any): object is HeaderGroup<RowType>[] {
  if (Array.isArray(object)) {
    if (object.length > 0) {
      return 'headers' in object[0];
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function instanceOfHeaderGroup<RowType>(object: any): object is HeaderGroup<RowType> {
  return 'headers' in object;
}

export function instanceOfHeaderArray<RowType>(object: any): object is Header<RowType>[] {
  if (Array.isArray(object)) {
    if (object.length > 0) {
      return 'columnDefinition' in object[0];
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function instanceOfHeader<RowType>(object: any): object is Header<RowType> {
  return 'def' in object;
}

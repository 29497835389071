import React from 'react';
import StatsBox from './stats-box';
import { AwsCostOverviewPage as page } from '..';
import useAccountCosts, { AwsAccountCost } from '../../services/useAwsLastestCosts';
import ErrorBox from './error-box';

const AccountsCostStats: React.FC = () => {
  const {
    data: accountCostsData,
    error: accountsCostError,
    isLoading: accountsCostIsLoading,
    isError: accountCostIsError
  } = useAccountCosts();

  if (accountCostIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={accountsCostError} />
      </React.Fragment>
    );
  }

  function calculateTotalAccounts(costs: AwsAccountCost[]): number {
    if (costs.length > 0) {
      return costs.reduce((acc, cost) => {
        return acc + Number(parseFloat(cost.cost));
      }, 0);
    }
    return 0;
  }

  return (
    <React.Fragment>
      <StatsBox loading={accountsCostIsLoading} page={page}>
        ${`${calculateTotalAccounts(accountCostsData?.result ?? []).toFixed(2)}`} / day
      </StatsBox>
    </React.Fragment>
  );
};

export default AccountsCostStats;

import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

const OrganizationChooser = () => {
  const [organization, setOrganization] = React.useState(process.env.REACT_APP_ORG || '');

  const handleChange = (event: SelectChangeEvent) => {
    setOrganization(event.target.value as string);
    const stage = process.env.REACT_APP_STAGE as 'staging' | 'production';
    const org = event.target.value as 'p934dq569e' | 'yozh4idta4';
    const newHost = lookupHost[stage][org];
    const currentPath = window.location.pathname + window.location.search + window.location.hash;
    window.location.href = window.location.protocol + '//' + newHost + currentPath;
  };

  const lookupHost = {
    staging: {
      p934dq569e: 'overview.test-01.topdanmark.cloud',
      yozh4idta4: 'overview.sandbox-xxx-01.topdanmark.cloud'
    },
    production: {
      p934dq569e: 'overview.prod-01.topdanmark.cloud',
      yozh4idta4: 'overview.master-dev-01.topdanmark.cloud'
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        color: 'inherit',
        paddingLeft: 2,
        width: '240px'
      }}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={organization}
        label="Age"
        onChange={handleChange}
        variant="standard"
        sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
      >
        <MenuItem value="p934dq569e">Current production organization</MenuItem>
        <MenuItem value="yozh4idta4">Development organization</MenuItem>
      </Select>
    </Box>
  );
};

export default OrganizationChooser;

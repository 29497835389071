import React from 'react';
import { Paper, Grid, Typography, Skeleton, Tooltip } from '@mui/material';
import { Page } from '..';
import { Link as ReactRouterLink } from 'react-router-dom';

const StatsBox: React.FC<{ page: Page; loading: boolean; children?: React.ReactNode }> = (props) => {
  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: props.page.color[100],
          width: 350,
          height: 200,
          borderRadius: 5,
          px: 5
        }}
        square={false}
      >
        <Tooltip title={props.page.tooltip}>
          <ReactRouterLink to={props.page.link} style={{ textDecoration: 'none', color: props.page.color[500] }}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                {props.loading ? <Skeleton></Skeleton> : <props.page.icon size="large" color={props.page.color[300]} />}
              </Grid>
              <Grid item xs={12}>
                {props.loading ? (
                  <Skeleton></Skeleton>
                ) : (
                  <React.Fragment>
                    <Typography variant="h6" textAlign="center">
                      {props.page.description}
                    </Typography>
                    {props.page.subDescription && (
                      <Typography variant="body1" textAlign="center">
                        {props.page.subDescription}
                      </Typography>
                    )}
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                {props.loading ? (
                  <Skeleton></Skeleton>
                ) : (
                  <Typography variant="h5" textAlign="center" fontWeight="bolder">
                    {props.children}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </ReactRouterLink>
        </Tooltip>
      </Paper>
    </React.Fragment>
  );
};

export default StatsBox;

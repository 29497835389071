import React from 'react';
import { ImagableColumnDefinition } from '../types';
import { TableCell, Tooltip } from '@mui/material';
import { Cell, Identible, Tooltipable } from '../types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export interface BooleanCell extends Cell<boolean>, Identible, Tooltipable {}

function renderBooleanCell(cell: BooleanCell, columnDef: ImagableColumnDefinition) {
  function renderCellContent(cell: BooleanCell) {
    return <span>{columnDef.imageMap[`${cell.value}`]}</span>;
  }

  return (
    <TableCell key={cell.id} align={BooleanColumnDefinition.align}>
      {cell.tooltip ? <Tooltip title={cell.tooltip}>{renderCellContent(cell)}</Tooltip> : renderCellContent(cell)}
    </TableCell>
  );
}

function booleanComparator<RowType>(sortBy: keyof RowType) {
  return (a: RowType, b: RowType) => {
    const aObj = a[sortBy] as unknown as { value: boolean };
    const bObj = b[sortBy] as unknown as { value: boolean };
    if (aObj.value && bObj.value) {
      const aNumber = aObj.value ? 1 : 0;
      const bNumber = bObj.value ? 1 : 0;
      if (aNumber < bNumber) {
        return -1;
      }
      if (aNumber > bNumber) {
        return 1;
      }
      return 0;
    } else {
      if (!aObj.value) {
        return -1;
      }
      if (!bObj.value) {
        return 1;
      }
      return 0;
    }
  };
}

export const BooleanColumnDefinition: ImagableColumnDefinition = {
  sortable: true,
  filterable: false,
  align: 'center',
  width: '1px',
  render: (rows, columnDefinition) => renderBooleanCell(rows, columnDefinition as ImagableColumnDefinition),
  comparator: booleanComparator,
  imageMap: {
    true: <CheckIcon />,
    false: <ClearIcon />
  }
};

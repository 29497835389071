import { Grid, Tooltip, Button, Dialog, DialogTitle, DialogContent, DialogContentText, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import moment from 'moment';
import { CloudfrontDetails } from './cloudfront-details';
import { CommonDetails } from './common-details';
import ReactJson from 'react-json-view';

interface Props {
  status: string;
  createdAt: string;
  activeFrom: string;
  issueDate: string;
  issuer: string;
  usedBy: string[];
  accountId: string;
  configuration: Record<string, unknown>;
}

export const CertificateDetails: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const renderDate = (date: string) => {
    const fromNow = moment(date).fromNow();
    const actualDate = moment(date).format('LLL');
    return (
      <Tooltip title={actualDate}>
        <span>{fromNow}</span>
      </Tooltip>
    );
  };

  const renderUsedBy = (usedBy: string[]) => {
    if (usedBy.length === 0) {
      return <p>No one is using this certificate</p>;
    } else {
      let cloudfront = false;
      usedBy.forEach((item) => {
        if (item.includes('cloudfront')) {
          cloudfront = true;
        }
      });
      if (cloudfront) {
        return <CloudfrontDetails accountId={props.accountId} usedBy={usedBy} />;
      } else {
        return <CommonDetails usedBy={usedBy} />;
      }
    }
  };
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={3}>
        <b>Status:</b> {props.status}
      </Grid>
      <Grid item xs={7}>
        <b>Issuer:</b> {props.issuer ? props.issuer : 'N/A'}
      </Grid>
      <Grid item xs={2}>
        <Button variant="outlined" size="small" onClick={handleOpen}>
          Configuration details
        </Button>
        <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth={true} maxWidth={false}>
          <DialogTitle id="scroll-dialog-title">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              Configuration details
              <IconButton onClick={handleClose}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
              <ReactJson src={props.configuration} collapsed={false}></ReactJson>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
      <Grid item xs={3}>
        <b>Issue data:</b> {props.issueDate ? renderDate(props.issueDate) : 'N/A'}
      </Grid>
      <Grid item xs={3}>
        <b>Created:</b> {props.createdAt ? renderDate(props.createdAt) : 'N/A'}
      </Grid>
      <Grid item xs={6}>
        <b>Active from:</b> {props.activeFrom ? renderDate(props.activeFrom) : 'N/A'}
      </Grid>
      <Grid item xs={12}>
        {renderUsedBy(props.usedBy)}
      </Grid>
    </Grid>
  );
};

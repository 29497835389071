import React from 'react';
import useCountAwsResources from '../../services/useCountAwsResources';
import { AwsResourceOverviewPage as page } from '..';
import StatsBox from './stats-box';
import ErrorBox from './error-box';

type CountAwsResourceProps = {};

const CountAwsResourceStats: React.FC<CountAwsResourceProps> = () => {
  const {
    data: countAwsResources,
    error: countAwsResourcesError,
    isError: countAwsResourcesIsError,
    isLoading: countAwsResourcesIsLoading
  } = useCountAwsResources('resource');

  if (countAwsResourcesIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={countAwsResourcesError} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StatsBox loading={countAwsResourcesIsLoading} page={page}>
        {countAwsResources?.resultCount}
      </StatsBox>
    </React.Fragment>
  );
};

export default CountAwsResourceStats;

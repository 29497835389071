import React from 'react';
import { useLocation } from 'react-router-dom';
import SpecificAccountCosts from './specific-account-cost';
import AllAccountCosts from './all-account-cost';

function AwsCostOverview() {
  // get query param accountId from url
  const location = useLocation();
  const accountId = new URLSearchParams(location.search).get('accountId');
  return accountId ? <SpecificAccountCosts accountId={accountId} /> : <AllAccountCosts />;
}

export default AwsCostOverview;

import React from 'react';
import { TableCell, Tooltip } from '@mui/material';
import { BaseColumnDefinition } from '../types';
import { Cell, Identible, Suffixable, Tooltipable } from '../types';
import moment from 'moment';

export interface DateCell extends Cell<Date>, Identible, Tooltipable, Suffixable {}

export function renderDateCell(cell: DateCell) {
  function renderCellContent(cell: DateCell) {
    const from = moment(cell.value).fromNow();
    return <span>{cell.suffix ? `${from}${cell.suffix}` : from}</span>;
  }

  return (
    <TableCell key={cell.id} align={DateColumnDefinition.align}>
      {cell.tooltip ? <Tooltip title={cell.tooltip}>{renderCellContent(cell)}</Tooltip> : renderCellContent(cell)}
    </TableCell>
  );
}

function dateComparator<RowType>(sortBy: keyof RowType) {
  return (a: RowType, b: RowType) => {
    const aObj = a[sortBy] as unknown as { value: Date };
    const bObj = b[sortBy] as unknown as { value: Date };
    if (aObj.value && bObj.value) {
      const aDate = new Date(aObj.value).getTime();
      const bDate = new Date(bObj.value).getTime();
      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      return 0;
    } else {
      if (!aObj.value) {
        return -1;
      }
      if (!bObj.value) {
        return 1;
      }
      return 0;
    }
  };
}

function dateFilterFn(cell: Cell<unknown>) {
  return (filterValue: string): boolean => {
    const dateCell = cell as DateCell;
    const from = moment(dateCell.value).fromNow();
    const searchString = dateCell.suffix ? `${from}${dateCell.suffix}` : from;
    return searchString.toLowerCase().includes(filterValue.toLowerCase());
  };
}

export const DateColumnDefinition: BaseColumnDefinition = {
  sortable: true,
  filterable: true,
  align: 'left',
  render: renderDateCell,
  comparator: dateComparator,
  filterFn: dateFilterFn
};

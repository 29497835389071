import { Container, Grid } from '@mui/material';
import React from 'react';
import CountAwsResourceStats from './aws-resources-stats';
import HyperplaneStats from './hyperplane-stats';
import CertificateStats from './certificate-stats';
import AzureLoginStats from './azure-login-stats';
import AccountsMetadataStats from './aws-account-stats';
import AccountsCostStats from './aws-cost-stats';
import AwsHostedZoneStats from './aws-hosted-zone-stats';
import AwsCloudFrontStats from './aws-cloudfront-stats';

function Welcome() {
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Grid container spacing={3} py={5}>
          <Grid item py={3}>
            <CountAwsResourceStats></CountAwsResourceStats>
          </Grid>
          <Grid item py={3}>
            <HyperplaneStats></HyperplaneStats>
          </Grid>
          <Grid item py={3}>
            <CertificateStats></CertificateStats>
          </Grid>
          <Grid item py={3}>
            <AzureLoginStats></AzureLoginStats>
          </Grid>
          <Grid item py={3}>
            <AccountsMetadataStats></AccountsMetadataStats>
          </Grid>
          <Grid item py={3}>
            <AccountsCostStats></AccountsCostStats>
          </Grid>
          <Grid item py={3}>
            <AwsHostedZoneStats></AwsHostedZoneStats>
          </Grid>
          <Grid item py={3}>
            <AwsCloudFrontStats></AwsCloudFrontStats>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Welcome;

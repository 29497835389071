import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { Header, HeaderGroup } from '../../components/tablev2/types';
import { ICTable } from '../../components/tablev2/ic-table';
import { Identible } from '../../components/tablev2/types';
import { ResourceType } from '../../services/useCountAwsResources';
import { hash } from '../../hashing';

interface RowData extends Identible {
  resourceName: StringCell;
  quantity: NumberCell;
}

const headers: Header<RowData>[] = [
  {
    id: 'AllAccountsHeader1',
    value: 'Resource Name',
    rowType: 'resourceName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AllAccountsHeader2',
    value: 'Quantity',
    rowType: 'quantity',
    columnDefinition: NumberColumnDefinition
  }
];

const headerGroups: HeaderGroup<RowData>[] = [
  {
    id: 'AllAccountsHeaderGroup1',
    value: 'Resources overview for all accounts',
    align: 'left',
    headers
  }
];

const buildRows = (fromData: ResourceType[]): RowData[] => {
  const data: RowData[] = [];
  fromData.forEach((type) => {
    const hashedId = `id${hash(type.item)}`;
    data.push({
      id: hashedId,
      resourceName: { value: type.item, id: `resourceName${hashedId}` },
      quantity: { value: type.quantity, id: `quantity${hashedId}` }
    });
  });
  return data;
};

export function ResourceQuantityAllAccounts({ data }: { data: ResourceType[] }) {
  return (
    <ICTable headers={headerGroups} rows={buildRows(data)} orderBy={'quantity'} order={'desc'} filterable={true} />
  );
}

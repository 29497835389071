export interface ApiEndpoint {
  ORGANISATIONS_API_ENDPOINT: string;
  EC2_API_ENDPOINT: string;
  CONFIG_API_ENDPOINT: string;
  AZURE_API_ENDPOINT: string;
  ACCOUNTS_API_ENDPOINT: string;
  STATISTICS_API_ENDPOINT: string;
  COSTS_API_ENDPOINT: string;
}

export default function getApiConfig(
  environment: 'staging' | 'production',
  organization: 'p934dq569e' | 'yozh4idta4'
): ApiEndpoint {
  return apiConfig[environment][organization];
}
/**
 * Finds the correct API endpoints for the given environment and organization.
 *
 * The environment and organization is supplied when building the source code.
 *
 * Organization is the organization id without the o- prefix.
 *
 * @see package.json script section for the build command
 */
const apiConfig = {
  staging: {
    p934dq569e: {
      ORGANISATIONS_API_ENDPOINT: 'https://api.master-prod-01.topdanmark.cloud/organizations-staging',
      EC2_API_ENDPOINT: 'https://api.network-prod-01.topdanmark.cloud/ec2-staging',
      CONFIG_API_ENDPOINT: 'https://api.master-prod-01.topdanmark.cloud/config-staging',
      AZURE_API_ENDPOINT: 'https://api.prod-01.topdanmark.cloud/azure-staging',
      ACCOUNTS_API_ENDPOINT: 'https://api.prod-01.topdanmark.cloud/accounts-staging',
      STATISTICS_API_ENDPOINT: 'https://api.prod-01.topdanmark.cloud/statistics-staging',
      COSTS_API_ENDPOINT: 'https://api.master-prod-01.topdanmark.cloud/costs-staging'
    },
    yozh4idta4: {
      ORGANISATIONS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/organizations-staging',
      EC2_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/ec2-staging',
      CONFIG_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/config-staging',
      AZURE_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/azure-staging',
      ACCOUNTS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/accounts-staging',
      STATISTICS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/statistics-staging',
      COSTS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/costs-staging'
    }
  },
  production: {
    p934dq569e: {
      ORGANISATIONS_API_ENDPOINT: 'https://api.master-prod-01.topdanmark.cloud/organizations',
      EC2_API_ENDPOINT: 'https://api.network-prod-01.topdanmark.cloud/ec2',
      CONFIG_API_ENDPOINT: 'https://api.master-prod-01.topdanmark.cloud/config',
      AZURE_API_ENDPOINT: 'https://api.prod-01.topdanmark.cloud/azure',
      ACCOUNTS_API_ENDPOINT: 'https://api.prod-01.topdanmark.cloud/accounts',
      STATISTICS_API_ENDPOINT: 'https://api.prod-01.topdanmark.cloud/statistics',
      COSTS_API_ENDPOINT: 'https://api.master-prod-01.topdanmark.cloud/costs'
    },
    yozh4idta4: {
      ORGANISATIONS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/organizations',
      EC2_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/ec2',
      CONFIG_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/config',
      AZURE_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/azure',
      ACCOUNTS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/accounts',
      STATISTICS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/statistics',
      COSTS_API_ENDPOINT: 'https://api.master-dev-01.topdanmark.cloud/costs'
    }
  }
};

import { Container, Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';

export default function LoginInProcess() {
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3} sx={{ width: '80%' }}>
            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
              Single sign on is in process... Please wait!!
            </Typography>
            <LinearProgress />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

import * as React from 'react';
import { Container, Skeleton } from '@mui/material';
import useHyperplanes, { HyperplanesPayload } from '../../services/useHyperplanes';
import useOrganizationAccounts, { OrganisationAccountsPayload } from '../../services/useOrganizationAccounts';
import HyperplaneTable from './hyperplane-table';
import { Header, Identible } from '../../components/tablev2/types';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { ICTable } from '../../components/tablev2/ic-table';
import { hash } from '../../hashing';
import Error from '../error';

interface RowData extends Identible {
  accountName: StringCell;
  accountNumber: StringCell;
  noOfUsedHyperplanes: NumberCell;
  expandedElement?: React.ReactNode;
}

const headers: Header<RowData>[] = [
  {
    id: 'HeaderGroup1Header1',
    value: 'Account name',
    rowType: 'accountName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header2',
    value: 'Account number',
    rowType: 'accountNumber',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'HeaderGroup1Header3',
    value: 'No. of used hyperplanes',
    rowType: 'noOfUsedHyperplanes',
    columnDefinition: NumberColumnDefinition
  }
];

function buildRows(hyperplanes: HyperplanesPayload[], accounts: OrganisationAccountsPayload[]): RowData[] {
  const r: { [accountId: string]: HyperplanesPayload[] } = {};
  hyperplanes?.forEach((hyperplane) => {
    if (r[`_${hyperplane.OwnerId}`]) {
      r[`_${hyperplane.OwnerId}`].push(hyperplane);
    } else {
      r[`_${hyperplane.OwnerId}`] = [];
      r[`_${hyperplane.OwnerId}`].push(hyperplane);
    }
  });

  const data: RowData[] = [];
  for (const p in r) {
    const hashedId = `id${hash(p)}`;
    const acc = accounts?.find(({ Id }) => Id === p.substring(1));
    if (acc) {
      data.push({
        id: hashedId,
        accountName: { value: acc.Name, id: `accountName${hashedId}` },
        accountNumber: { value: p.substring(1), id: `accountNumber${hashedId}` },
        noOfUsedHyperplanes: { value: r[p].length, id: `noOfUsedHyperplanes${hashedId}` },
        expandedElement: <HyperplaneTable data={r[p]} />
      });
    } else {
      data.push({
        id: p,
        accountName: { value: 'N/A', id: `accountName${hashedId}` },
        accountNumber: { value: p.substring(1), id: `accountNumber${hashedId}` },
        noOfUsedHyperplanes: { value: r[p].length, id: `noOfUsedHyperplanes${hashedId}` },
        expandedElement: <HyperplaneTable data={r[p]} />
      });
    }
  }
  return data;
}

function HyperplaneOverview() {
  const {
    data: hyperplanes,
    error: hyperplanesError,
    isError: hyperplanesIsError,
    isLoading: hyperplanesIsLoading
  } = useHyperplanes();
  const { data: organisationAccounts, isLoading: organisationAccountsIsLoading } = useOrganizationAccounts();

  if (hyperplanesIsError) {
    return <Error customMessage="Error while trying to fetch hyperplanes" errorMessage={hyperplanesError.message} />;
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5 }}>
      {hyperplanesIsLoading || organisationAccountsIsLoading ? (
        <Skeleton variant="rectangular" height={300}></Skeleton>
      ) : (
        hyperplanes &&
        organisationAccounts && (
          <ICTable
            headers={headers}
            rows={buildRows(hyperplanes, organisationAccounts)}
            orderBy="accountName"
            filterable={true}
            expandable={true}
          />
        )
      )}
    </Container>
  );
}
export default HyperplaneOverview;

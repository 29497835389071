import React from 'react';
import useAwsResources from '../../services/useAwsResources';
import StatsBox from './stats-box';
import { AwsHostedZoneOverviewPage as page } from '..';
import ErrorBox from './error-box';

type AwsHostedZoneProps = {};

const AwsHostedZoneStats: React.FC<AwsHostedZoneProps> = () => {
  const {
    data: awsHostedZones,
    error: awsHostedZonesError,
    isError: awsHostedZoneIsError,
    isLoading: awsHostedZoneIsLoading
  } = useAwsResources('AWS::Route53::HostedZone');

  if (awsHostedZoneIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={awsHostedZonesError} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StatsBox loading={awsHostedZoneIsLoading} page={page}>
        {awsHostedZones?.resultCount}
      </StatsBox>
    </React.Fragment>
  );
};

export default AwsHostedZoneStats;

import React from 'react';
import { green, red } from '@mui/material/colors';
import useAzureGraphApi from '../../services/useAzureGraphApi';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import StatsBox from './stats-box';
import { AzureLoginOverviewPage as page } from '..';
import ErrorBox from './error-box';

export default function AzureLoginStats() {
  const {
    data: azureLogins,
    error: azureLoginsError,
    isError: azureLoginsIsError,
    isLoading: azureLoginsIsLoading
  } = useAzureGraphApi('D30');

  if (azureLoginsIsError) {
    return (
      <React.Fragment>
        <ErrorBox page={page} error={azureLoginsError} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StatsBox loading={azureLoginsIsLoading} page={page}>
        <React.Fragment>
          <React.Fragment>
            {azureLogins?.totalSuccessfulSignInCount}
            <DoneIcon sx={{ color: green[300] }}></DoneIcon>
          </React.Fragment>
          /
          <React.Fragment>
            {azureLogins?.totalFailedSignInCount}
            <CloseIcon sx={{ color: red[300] }}></CloseIcon>
          </React.Fragment>
        </React.Fragment>
      </StatsBox>
    </React.Fragment>
  );
}

import { LinearProgress, Stack } from '@mui/material';
import * as React from 'react';
import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { Header } from '../../components/tablev2/types';
import { ICTable } from '../../components/tablev2/ic-table';
import { Identible } from '../../components/tablev2/types';
import useCountAwsResources, { ResourceType } from '../../services/useCountAwsResources';
import Error from '../error';
import { hash } from '../../hashing';

interface RowData extends Identible {
  resourceName: StringCell;
  quantity: NumberCell;
}

const headers: Header<RowData>[] = [
  {
    id: 'AccountDetailsHeader1',
    value: 'Resource',
    rowType: 'resourceName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AccountDetailsHeader2',
    value: 'Quantity',
    rowType: 'quantity',
    columnDefinition: NumberColumnDefinition
  }
];

const buildRows = (fromData: ResourceType[]): RowData[] => {
  const data: RowData[] = [];
  fromData.forEach((type) => {
    const hashedId = `id${hash(type.item)}`;
    data.push({
      id: hashedId,
      resourceName: { value: type.item, id: `resourceName${hashedId}` },
      quantity: { value: type.quantity, id: `quantity${hashedId}` }
    });
  });
  return data;
};

export function ResourceAccountDetails({ accountNumber }: { accountNumber: string }) {
  const {
    data: countAwsResources,
    isLoading: countAwsResourcesLoading,
    error: countAwsResourcesError,
    isError: countAwsResourcesIsError
  } = useCountAwsResources('resource', accountNumber);

  if (countAwsResourcesIsError) {
    return (
      <Error
        customMessage={`Error while trying to fetch AWS resources by account number ${accountNumber}`}
        errorMessage={countAwsResourcesError.message}
      />
    );
  }

  return (
    <React.Fragment>
      {countAwsResourcesLoading ? (
        <Stack spacing={5}>
          <LinearProgress />
        </Stack>
      ) : (
        countAwsResources?.result && (
          <ICTable
            headers={headers}
            rows={buildRows(countAwsResources.result)}
            orderBy={'quantity'}
            order={'desc'}
            filterable={true}
            stripedRows={true}
          />
        )
      )}
    </React.Fragment>
  );
}

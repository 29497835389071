import React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { red } from '@mui/material/colors';
import { Page } from '..';

const ErrorBox: React.FC<{ page: Page; error: Error; children?: React.ReactNode }> = (props) => {
  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: red[500],
          width: 350,
          height: 200,
          borderRadius: 5,
          px: 5
        }}
        square={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <ErrorIcon fontSize="large" sx={{ color: red[100] }} />
          </Grid>
          <Grid item xs={12}>
            <React.Fragment>
              <Typography variant="h6" textAlign="center">
                {props.page.errorMessage}
              </Typography>
              <Typography variant="body1" textAlign="center">
                Error message: {props.error.message}
              </Typography>
            </React.Fragment>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="center" fontWeight="bolder">
              {props.children}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default ErrorBox;

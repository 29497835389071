import React from 'react';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { deepPurple } from '@mui/material/colors';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Typography
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import KeyIcon from '@mui/icons-material/Key';

function stringAvatar(name?: string) {
  if (!name) return;
  const nameParts = name.split(' ');
  let initials = '';
  if (nameParts.length === 0) {
    initials = 'N/A';
  } else if (nameParts.length === 1) {
    initials = nameParts[0][0];
  } else if (nameParts.length === 2) {
    initials = nameParts[0][0] + nameParts[1][0];
  } else {
    initials = nameParts[0][0] + nameParts[nameParts.length - 1][0];
  }
  return {
    sx: {
      bgcolor: deepPurple[500]
    },
    children: initials
  };
}

interface Props {
  isAdmin?: boolean;
  loggedIn: boolean;
  name?: string;
  jwt?: string;
}

const UserLoginIcon: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCopyJwtToClipboard = () => {
    navigator.clipboard.writeText(props.jwt || '');
    handleClose();
  };

  const renderMenu = () => {
    return (
      <ListItem>
        <ListItemButton onClick={handleCopyJwtToClipboard}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary="Copy JWT token" />
        </ListItemButton>
      </ListItem>
    );
  };
  return (
    <div style={{ marginLeft: '10px' }}>
      {props.loggedIn ? (
        <div>
          <Tooltip title={`You are logged in as ${props.name}`} enterDelay={500} leaveDelay={200}>
            <IconButton onClick={handleClick}>
              <Avatar {...stringAvatar(props.name)} />
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <div>
                    <Typography variant="h6">
                      Personal settings for {props.name}
                      {props.isAdmin ? (
                        <Tooltip title={`You are login as an administrator`} enterDelay={500} leaveDelay={200}>
                          <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </Typography>
                  </div>
                </ListSubheader>
              }
            >
              {renderMenu()}
            </List>
          </Popover>
        </div>
      ) : (
        <Tooltip title={`You are not logged in`} enterDelay={500} leaveDelay={200}>
          <Avatar>
            <LockOpenRoundedIcon></LockOpenRoundedIcon>
          </Avatar>
        </Tooltip>
      )}
    </div>
  );
};

export default UserLoginIcon;

import * as React from 'react';
import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { Header, HeaderGroup } from '../../components/tablev2/types';
import { ICTable } from '../../components/tablev2/ic-table';
import { Identible } from '../../components/tablev2/types';
import { ResourceType } from '../../services/useCountAwsResources';
import { OrganisationAccountsPayload } from '../../services/useOrganizationAccounts';
import { ResourceAccountDetails } from './resource-account-details';
import { hash } from '../../hashing';

interface RowData extends Identible {
  accountNumber: StringCell;
  accountName: StringCell;
  quantity: NumberCell;
  expandedElement: React.ReactNode;
}

const headers: Header<RowData>[] = [
  {
    id: 'AccountsHeader1',
    value: 'Account number',
    rowType: 'accountNumber',
    columnDefinition: { ...StringColumnDefinition, width: '180px' }
  },
  {
    id: 'AccountsHeader2',
    value: 'Account name',
    rowType: 'accountName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'AccountsHeader3',
    value: 'Quantity',
    rowType: 'quantity',
    columnDefinition: NumberColumnDefinition
  }
];

const headerGroups: HeaderGroup<RowData>[] = [
  {
    id: 'AccountsHeaderGroup1',
    value: 'Resources overview by account',
    align: 'left',
    headers
  }
];

const buildRows = (fromData: ResourceType[], accounts: OrganisationAccountsPayload[]): RowData[] => {
  const data: RowData[] = [];
  fromData.forEach((type) => {
    const hashedId = `id${hash(type.item)}`;
    const acc = accounts?.find(({ Id }) => Id === type.item);
    data.push({
      id: hashedId,
      accountNumber: { value: type.item, id: `accountNumber${hashedId}` },
      accountName: { value: acc?.Name ?? 'N/A', id: `accountName${hashedId}` },
      quantity: { value: type.quantity, id: `quantity${hashedId}` },
      expandedElement: <ResourceAccountDetails accountNumber={type.item} />
    });
  });
  return data;
};

export function ResourceQuantityAccounts({
  data,
  accounts
}: {
  data: ResourceType[];
  accounts: OrganisationAccountsPayload[];
}) {
  return (
    <ICTable
      headers={headerGroups}
      rows={buildRows(data, accounts)}
      orderBy={'quantity'}
      order={'desc'}
      expandable={true}
      filterable={true}
    />
  );
}

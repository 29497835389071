import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import CloudTwoToneIcon from '@mui/icons-material/CloudTwoTone';

interface LogoProps {
  children?: React.ReactNode;
}

function Logo(props: LogoProps) {
  return (
    <Tooltip title="Go to frontpage">
      <ReactRouterLink to="/" style={{ textDecoration: 'none' }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', mr: '25px' }}>
          <CloudTwoToneIcon sx={{ mr: 1 }} />
          {props.children}
        </Box>
      </ReactRouterLink>
    </Tooltip>
  );
}

export default Logo;

import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface LinkIconProps {
  tooltip: string;
  linkTo: string;
  children: React.ReactNode;
}

function LinkIcon(props: LinkIconProps) {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', pr: '5px', pl: '5px' }}>
      <Tooltip title={props.tooltip}>
        <ReactRouterLink to={props.linkTo} style={{ textDecoration: 'none' }}>
          {props.children}
        </ReactRouterLink>
      </Tooltip>
    </Box>
  );
}

export default LinkIcon;

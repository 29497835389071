import React from 'react';
import { Alert, AlertTitle, Grid } from '@mui/material';

function Error({ customMessage, errorMessage }: { customMessage: string; errorMessage: string }) {
  return (
    <Alert severity="error" sx={{ padding: 2, margin: 2 }}>
      <AlertTitle>Error</AlertTitle>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <strong>Message:</strong>
        </Grid>
        <Grid item xs={12}>
          {customMessage}
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12}>
          <strong>Error message from service:</strong>
        </Grid>
        <Grid item xs={12}>
          {errorMessage}
        </Grid>
      </Grid>
    </Alert>
  );
}

export default Error;

import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

type Groups = {
  GroupName: string;
  GroupId: string;
};

type PrivateIpAddresses = {
  Primary: boolean;
  PrivateIpAddress: string;
};

export type HyperplanesPayload = {
  Attachment: {
    AttachmentId: string;
    DeleteOnTermination: boolean;
    DeviceIndex: number;
    InstanceOwnerId: string;
    Status: string;
  };
  AvailabilityZone: string;
  Description: string;
  Groups: Groups[];
  InterfaceType: string;
  Ipv6Addresses: string[];
  MacAddress: string;
  NetworkInterfaceId: string;
  OwnerId: string;
  PrivateIpAddress: string;
  PrivateIpAddresses: PrivateIpAddresses[];
  RequesterId: string;
  RequesterManaged: boolean;
  SourceDestCheck: boolean;
  Status: string;
  SubnetId: string;
  TagSet: string[];
  VpcId: string;
};

function useHyperplanes() {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const hyperplanesQuery = useQuery({
    queryKey: ['hyperplanes'],
    queryFn: async () => {
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_READ],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );
      const response = await fetch(endpoint.EC2_API_ENDPOINT + `/network-interfaces`, {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          'x-top-api-version': 'v1'
        }
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }
  });

  return {
    data: hyperplanesQuery.data as HyperplanesPayload[],
    error: hyperplanesQuery.error as Error,
    isLoading: hyperplanesQuery.isLoading,
    isError: hyperplanesQuery.isError,
    isFetching: hyperplanesQuery.isFetching,
    isSuccess: hyperplanesQuery.isSuccess,
    dataUpdatedAt: hyperplanesQuery.dataUpdatedAt
  };
}

export default useHyperplanes;

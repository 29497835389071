import React from 'react';
import { AwsResourceType } from '../../services/useAwsResources';
import { Grid } from '@mui/material';

interface Props {
  resource: AwsResourceType;
}

export const AwsHostedZoneDetails: React.FC<Props> = (props) => {
  function getTags(resource: AwsResourceType) {
    const conf = resource.configuration as Record<string, any>;
    if (!conf.HostedZoneTags) {
      return '';
    } else {
      return JSON.stringify(conf.HostedZoneTags, null, 2);
    }
  }

  function getConfig(resource: AwsResourceType) {
    const conf = resource.configuration as Record<string, any>;
    if (!conf.HostedZoneConfig) {
      return '';
    } else {
      return JSON.stringify(conf.HostedZoneConfig, null, 2);
    }
  }

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={3}>
        <b>Resource Id:</b> {props.resource.resourceId}
      </Grid>
      <Grid item xs={7}>
        <b>Resource type:</b> {props.resource.resourceType}
      </Grid>
      <Grid item xs={3}>
        <b>Aws region:</b> {props.resource.awsRegion}
      </Grid>
      <Grid item xs={7}>
        <b>Resource Arn:</b> {props.resource.arn}
      </Grid>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={1}>
          <b>Hosted Zone tags:</b>
        </Grid>
        <Grid item xs={3}>
          <pre>{getTags(props.resource)}</pre>
        </Grid>
        <Grid item xs={1}>
          <b>Hosted Zone config:</b>
        </Grid>
        <Grid item xs={3}>
          <pre>{getConfig(props.resource)}</pre>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AwsHostedZoneDetails;

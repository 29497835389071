import React from 'react';
import { BaseColumnDefinition } from '../types';
import { TableCell, Tooltip } from '@mui/material';
import { Cell, Identible, Linkable, Suffixable, Tooltipable } from '../types';

// {
//   value: string; > Cell
//   filterValue?: string; > FilterableCell
//   id:  string; > Identible
//   tooltip?: string; > TooltipableCell
// }
export interface StringCell extends Cell<string>, Identible, Tooltipable, Suffixable, Linkable {}

function renderStringCell(cell: StringCell) {
  function renderCellContent(cell: StringCell) {
    if (cell.href) {
      return (
        <a href={cell.href} target={cell.target ?? '_blank'} rel="noreferrer">
          {cell.suffix ? `${cell.value}${cell.suffix}` : cell.value}
        </a>
      );
    } else {
      return <span>{cell.suffix ? `${cell.value}${cell.suffix}` : cell.value}</span>;
    }
  }

  return (
    <TableCell key={cell.id} align={StringColumnDefinition.align}>
      {cell.tooltip ? <Tooltip title={cell.tooltip}>{renderCellContent(cell)}</Tooltip> : renderCellContent(cell)}
    </TableCell>
  );
}

function stringComparator<RowType>(sortBy: keyof RowType) {
  return (a: RowType, b: RowType) => {
    const aObj = a[sortBy] as unknown as { value: string };
    const bObj = b[sortBy] as unknown as { value: string };
    if (aObj.value < bObj.value) {
      return -1;
    }
    if (aObj.value > bObj.value) {
      return 1;
    }
    return 0;
  };
}

function stringFilterFn(cell: Cell<unknown>) {
  return (filterValue: string): boolean => {
    const stringCell = cell as StringCell;
    const searchString = stringCell.suffix ? `${stringCell.value}${stringCell.suffix}` : stringCell.value;
    console.log('cell', cell);
    console.log('filterValue', filterValue);
    console.log('searchString', searchString);
    if (!searchString) {
      return false;
    }
    return searchString.toLowerCase().includes(filterValue.toLowerCase());
  };
}

export const StringColumnDefinition: BaseColumnDefinition = {
  sortable: true,
  filterable: true,
  align: 'left',
  width: 'auto',
  render: renderStringCell,
  comparator: stringComparator,
  filterFn: stringFilterFn
};

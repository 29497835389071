import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

export type AwsAccountCost = {
  accountId: string;
  costDate: string;
  cost: string;
};

export type AwsAccountCostsPayload = {
  resultCount: number;
  result: AwsAccountCost[];
};

function useAccountCosts() {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const accountCostsQuery = useQuery({
    queryKey: ['accountCosts'],
    queryFn: async () => {
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_READ],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );
      const response = await fetch(endpoint.COSTS_API_ENDPOINT + '/accounts?cost', {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          'x-top-api-version': 'v2'
        }
      });
      if (!response.ok) {
        throw new Error(response?.statusText || 'No status text');
      }
      return response.json();
    },
    staleTime: 1000 * 60 * 60 * 12 // 12 hours
  });

  return {
    data: accountCostsQuery.data as AwsAccountCostsPayload,
    error: accountCostsQuery.error as Error,
    isLoading: accountCostsQuery.isLoading,
    isError: accountCostsQuery.isError,
    isFetching: accountCostsQuery.isFetching,
    isSuccess: accountCostsQuery.isSuccess,
    dataUpdatedAt: accountCostsQuery.dataUpdatedAt
  };
}

export default useAccountCosts;

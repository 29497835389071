import { FilterList } from '@mui/icons-material';
import { Box, Grid, Paper, Table, TableContainer, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { PropsWithChildren, useEffect } from 'react';
import { renderTableBody } from './body';
import { Header, HeaderGroup } from './types';
import { instanceOfHeaderArray, instanceOfHeaderGroupArray } from './type-guards';
import { Cell, Identible, SortDirection } from './types';
import { renderTableHeaders } from './headers';

interface Props<RowType extends Identible> {
  /**
   * If 'true' each row can be expanded to show more details.
   * @default false
   */
  expandable?: boolean;

  /**
   * If expandable is set to true the background color of the expanded row
   * will be set to this.
   * @default grey[200]
   */
  expandableBackgroundColor?: string;

  /**
   * If 'true' the table will support filtering of data
   * with a search bar at the top.
   * @default false
   */
  filterable?: boolean;

  /**
   * If 'true' the table will display the number of rows in the table.
   * @default true
   */
  displayNumberOfRows?: boolean;

  /**
   * The data property that will be used to sort the table on first render.
   */
  orderBy: keyof RowType;

  /**
   * The direction that the table will be sorted on first render.
   * @default 'desc'
   */
  order?: SortDirection;

  /**
   * The rows of data to be displayed in the table.
   */
  rows: RowType[];

  /**
   * If 'true' every other row will render in another color
   * Will not work as expected if `expandable` is set to `true`
   * the expanded rows will always be rendered in the same color
   * @default false
   */
  stripedRows?: boolean;

  /**
   * The headers of the table.
   */
  headers: HeaderGroup<RowType>[] | Header<RowType>[];

  /**
   * The color of the text in the header row.
   * @default 'common.black'
   */
  headerFontColor?: string;

  /**
   * The color of the text in the header row.
   * @default 'common.black'
   */
  headerGroupFontColor?: string;

  /**
   * The height of the rows in the table.
   * @default 'small'
   */
  tableSize?: 'small' | 'medium';
}

export function ICTable<RowType extends Identible>(props: PropsWithChildren<Props<RowType>>) {
  const [sortBy, setSortBy] = React.useState<keyof RowType>(props.orderBy);
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(props.order || 'desc');
  const [filter, setFilter] = React.useState<string>('');
  const [rows, setRows] = React.useState<RowType[]>(props.rows);

  useEffect(() => {
    function getFilterFn(rowType: string, cellData: Cell<unknown>) {
      let header;
      if (instanceOfHeaderGroupArray(props.headers)) {
        header = props.headers
          .map((headerGroup) => headerGroup.headers)
          .flat()
          .find((header) => header.rowType === rowType);
      } else if (instanceOfHeaderArray(props.headers)) {
        header = props.headers.find((el) => el.rowType === rowType);
      }

      if (header === undefined) {
        return null;
      }
      if (header.columnDefinition.filterFn === undefined) {
        return null;
      }

      return header.columnDefinition.filterFn(cellData);
    }

    // eslint-disable-next-line no-unused-vars
    function getComparator(sortDirection: SortDirection, sortBy: keyof RowType): (a: RowType, b: RowType) => number {
      let sortHeader;
      if (instanceOfHeaderGroupArray(props.headers)) {
        sortHeader = props.headers
          .map((headerGroup) => headerGroup.headers)
          .flat()
          .find((header) => header.rowType === sortBy);
      } else if (instanceOfHeaderArray(props.headers)) {
        sortHeader = props.headers.find((el) => el.rowType === sortBy);
      }
      if (sortHeader === undefined) {
        throw new Error('Could not find header for sorting');
      }
      if (sortHeader.columnDefinition.comparator === undefined) {
        throw new Error('Comparator is undefined');
      }
      const comparator = sortHeader.columnDefinition.comparator(sortBy);
      return sortDirection === 'asc' ? comparator : (a, b) => -comparator(a, b);
    }

    let rowsFromProps = props.rows;
    if (props.filterable) {
      rowsFromProps = props.rows.filter((row) => {
        if (!filter) {
          return true;
        } else {
          let filters: Array<boolean> = [];
          for (const [key, cell] of Object.entries(row)) {
            const filterFn = getFilterFn(key, cell);
            if (filterFn) {
              filters.push(filterFn(filter));
            }
          }
          return !filters.every((element) => element === false);
        }
      });
    }

    const comparator = getComparator(sortDirection, sortBy);
    rowsFromProps = [...rowsFromProps].sort(comparator);
    setRows(rowsFromProps);
  }, [filter, sortBy, sortDirection, props.rows, props.filterable, props.headers]);

  function getDisplayedRows(rows: number) {
    switch (rows) {
      case 0:
        return 'No rows displayed';
      case 1:
        return '1 row displayed';
      default:
        return `${rows} rows displayed`;
    }
  }

  return (
    <Box sx={{ border: 'none', mt: 2 }}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        {props.filterable ? (
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 1 }}>
            <FilterList sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              id="filterField"
              label="filter field"
              type="search"
              variant="standard"
              size="small"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Box>
        ) : (
          <React.Fragment>&nbsp;</React.Fragment>
        )}
        {(props.displayNumberOfRows ?? true) && (
          <Typography sx={{ fontWeight: 'bolder', color: 'GrayText' }}>{getDisplayedRows(rows.length)}</Typography>
        )}
      </Grid>
      <TableContainer component={Paper}>
        <Table size={props.tableSize ?? 'small'}>
          {renderTableHeaders(
            props.headers,
            sortBy,
            sortDirection,
            setSortBy,
            setSortDirection,
            props.expandable ?? false
          )}
          {instanceOfHeaderGroupArray<RowType>(props.headers)
            ? renderTableBody(
                props.headers.flatMap((headerGroup) => headerGroup.headers),
                rows,
                props.expandable ? false : props.stripedRows ?? false,
                props.expandable ?? false,
                props.expandableBackgroundColor ?? grey[200]
              )
            : renderTableBody<RowType>(
                props.headers,
                rows,
                props.expandable ? false : props.stripedRows ?? false,
                props.expandable ?? false,
                props.expandableBackgroundColor ?? grey[200]
              )}
        </Table>
      </TableContainer>
    </Box>
  );
}

import React from 'react';
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Tooltip
} from '@mui/material';
import useAccountCosts, { AwsAccountCost } from '../../services/useAwsLastestCosts';
import Error from '../error';
import { ICTable } from '../../components/tablev2/ic-table';
import { hash } from '../../hashing';
import { Header, HeaderGroup, Identible } from '../../components/tablev2/types';
import { StringCell, StringColumnDefinition } from '../../components/tablev2/cells/string-cell';
import { NumberCell, NumberColumnDefinition } from '../../components/tablev2/cells/number-cell';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import useAccountsMetadata, { AccountMetadataType } from '../../services/useAwsAccountsMetadata';

export interface RowData extends Identible {
  accountName: StringCell;
  accountNumber: StringCell;
  accountCostDate: StringCell;
  accountCost: NumberCell;
}

const headers: Header<RowData>[] = [
  {
    id: 'CostHeader1',
    value: 'Account Name',
    rowType: 'accountName',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CostHeader2',
    value: 'Account Number',
    rowType: 'accountNumber',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CostHeader3',
    value: 'Cost Date',
    tooltip: 'The last date we have cost data for. Hover over the value to see the exact date.',
    rowType: 'accountCostDate',
    columnDefinition: StringColumnDefinition
  },
  {
    id: 'CostHeader4',
    value: 'Cost',
    rowType: 'accountCost',
    columnDefinition: NumberColumnDefinition
  }
];

const headerGroups: HeaderGroup<RowData>[] = [
  {
    id: 'CostHeaderGroup1',
    value: 'AWS Cost Overview for all accounts',
    align: 'left',
    headers
  }
];

function buildRows(accountCostData: AwsAccountCost[], accountMetadataData: AccountMetadataType[]) {
  const data: RowData[] = [];
  accountCostData.forEach((cost) => {
    const hashedId = `id${hash(cost.accountId + cost.costDate + cost.cost)}`;
    data.push({
      id: hashedId,
      accountName: {
        value: accountMetadataData.find((metadata) => metadata.accountId === cost.accountId)?.accountName || '',
        id: `accountName${hashedId}`
      },
      accountNumber: {
        value: cost.accountId,
        id: `accountNumber${hashedId}`,
        tooltip: `Clicking on this value will take you to the cost overview page for this account`,
        href: `/aws-cost-overview?accountId=${cost.accountId}`,
        target: '_self'
      },
      accountCostDate: {
        value: moment(cost.costDate).format('YYYY-MM-DD'),
        tooltip: `Cost data for ${moment(cost.costDate).format('MMMM Do YYYY')}`,
        id: `accountCostDate${hashedId}`
      },
      accountCost: { value: parseFloat(Number(cost.cost).toFixed(2)), id: `accountCost${hashedId}`, prefix: '$' }
    });
  });
  return data;
}

function AllAccountCosts() {
  const [graphDisplay, setGraphDisplay] = React.useState<'all' | 'top10'>('top10');
  const {
    data: accountsCostData,
    error: accountsCostError,
    isLoading: accountsCostIsLoading,
    isError: accountsCostIsError,
    dataUpdatedAt: accountsCostUpdatedAt
  } = useAccountCosts();

  const {
    data: accountsMetadataData,
    isLoading: accountMetadataIsLoading,
    isError: accountMetadataIsError,
    error: accountMetadataError
  } = useAccountsMetadata();

  if (accountsCostIsError) {
    return (
      <Error customMessage={`Error while trying to fetch account cost data`} errorMessage={accountsCostError.message} />
    );
  }

  if (accountMetadataIsError) {
    return (
      <Error
        customMessage={`Error while trying to fetch account metadata`}
        errorMessage={accountMetadataError.message}
      />
    );
  }

  function transformData() {
    const data = accountsCostData?.result || [];
    const displayedData =
      graphDisplay === 'all' ? data : data.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost)).slice(0, 10);
    return {
      labels: displayedData.map(
        (metadata) =>
          accountsMetadataData.result.find((meta) => meta.accountId === metadata.accountId)?.accountName ||
          metadata.accountId
      ),
      datasets: [
        {
          label: 'Cost',
          data: displayedData.map((cost) => parseFloat(Number(cost.cost).toFixed(2))),
          backgroundColor: displayedData.map(() => 'rgba(54, 162, 235, 0.2)'),
          borderColor: displayedData.map(() => 'rgba(54, 162, 235, 1)'),
          borderWidth: 1
        }
      ]
    };
  }

  const handleChangeGraphDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraphDisplay(event.target.value as 'all' | 'top10');
  };

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 5, paddingBottom: 10 }}>
      {accountsCostIsLoading || accountMetadataIsLoading ? (
        <Stack spacing={5}>
          <Skeleton variant="rectangular" height={200}></Skeleton>
          <Skeleton variant="rectangular" height={300}></Skeleton>
        </Stack>
      ) : (
        accountsCostData && (
          <React.Fragment>
            <div>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Graph display: </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={graphDisplay}
                  onChange={handleChangeGraphDisplay}
                >
                  <FormControlLabel value="all" control={<Radio />} label="All accounts" />
                  <FormControlLabel value="top10" control={<Radio />} label="10 most expensive accounts" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <Bar data={transformData()} options={{ maintainAspectRatio: false, responsive: true }} height={200} />
            </div>

            <ICTable
              rows={buildRows(accountsCostData?.result || [], accountsMetadataData?.result || [])}
              headers={headerGroups}
              orderBy={'accountCost'}
              order={'desc'}
              filterable={true}
              stripedRows={true}
            />

            <Grid container justifyContent="space-between" alignItems="flex-end">
              <span>&nbsp;</span>
              <Tooltip title="Time for when the last recorded cost data was fetch from the server. Cost data is updated in the backend once a day.">
                <span style={{ fontSize: 'small', color: 'grey' }}>
                  Last time data was fetched from server:{' '}
                  {accountsCostIsLoading ? (
                    <strong>fetching...</strong>
                  ) : (
                    <strong>{new Date(accountsCostUpdatedAt).toLocaleTimeString()}</strong>
                  )}
                </span>
              </Tooltip>
            </Grid>
          </React.Fragment>
        )
      )}
    </Container>
  );
}

export default AllAccountCosts;

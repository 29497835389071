import React from 'react';
import AppBar from '@mui/material/AppBar';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/material';
import Logo from './logo';
import LogoText from './logo-text';
import Links from './links';
import LinkIconBar from './link-icon-bar';
import LoggedInUser from './logged-in-user';
import TopbarMenu from './topbar-menu';
import OrganizationChooser from './organization-chooser';

function Topbar() {
  const appBarRef = React.useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        ref={appBarRef}
      >
        {/* DISPLAY ON MOBILE */}
        <Toolbar sx={{ display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none', justifyContent: 'space-between' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Logo />
            <Links />
            <OrganizationChooser />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TopbarMenu appBarRef={appBarRef} />
            <LoggedInUser />
          </Box>
        </Toolbar>

        {/* DISPLAY ON IPAD HORIZONTAL */}
        <Toolbar sx={{ display: { xs: 'none', sm: 'flex', md: 'none', lg: 'none', justifyContent: 'space-between' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Logo />
            <Links />
            <OrganizationChooser />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TopbarMenu appBarRef={appBarRef} />
            <LoggedInUser />
          </Box>
        </Toolbar>

        {/* DISPLAY ON IPAD VERTICAL */}
        <Toolbar sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'none', justifyContent: 'space-between' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Logo />
            <Links />
            <OrganizationChooser />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <LinkIconBar />
            <LoggedInUser />
          </Box>
        </Toolbar>

        {/* DISPLAY ON DESKTOP */}
        <Toolbar
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: 1 }}>
            <LogoText />
            <Links />
            <OrganizationChooser />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <LinkIconBar />
            <LoggedInUser />
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default Topbar;

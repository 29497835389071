import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { isAdmin } from '../role-checks';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

export type Login = {
  userName: string;
  userRoles: string[];
  date: string;
};

interface LoginStatisticsPayload {
  resultCount: number;
  result: Login[];
}

interface LoginStatisticsProps {
  period?: {
    year: string;
    startMonth?: string;
    endMonth?: string;
  };
  user?: {
    userName: string;
    startLoginTime?: string;
    endLoginTime?: string;
  };
}

function useLoginStatistics(props: LoginStatisticsProps = {}) {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const loginStatisticsQuery = useQuery({
    queryKey: ['loginStatistics'],
    queryFn: async () => {
      if (!isAdmin(instance.getActiveAccount())) {
        throw new Error('You are not authorized to call the login statistics api');
      }
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_ADMIN],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );

      let queryString = '';
      if (props.period) {
        queryString += `?year=${props.period.year}`;
        if (props.period.startMonth) {
          queryString += `&startMonth=${props.period.startMonth}`;
        }
        if (props.period.endMonth) {
          queryString += `&endMonth=${props.period.endMonth}`;
        }
      }

      const response = await fetch(endpoint.STATISTICS_API_ENDPOINT + `/logins${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          'x-top-api-version': 'v1'
        }
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    refetchInterval: 1000 * 60 // 1 min
  });

  return {
    isError: loginStatisticsQuery.isError,
    isLoading: loginStatisticsQuery.isLoading,
    isSuccess: loginStatisticsQuery.isSuccess,
    isFetching: loginStatisticsQuery.isFetching,
    data: loginStatisticsQuery.data as LoginStatisticsPayload,
    error: loginStatisticsQuery.error as Error,
    dataUpdatedAt: loginStatisticsQuery.dataUpdatedAt
  };
}

export default useLoginStatistics;

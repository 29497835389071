import { useContext } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ApplicationScopesCtx from '../context/ApplicationScopesContext';
import { useQuery } from '@tanstack/react-query';
import getApiConfig from '../api-config';

export type AccountMetadataType = {
  organizationalUnitForAccount: string;
  accountEmail: string;
  accountId: string;
  accountJoined: string;
  accountJoinedTimestamp: string;
  accountCosts: string;
  accountName: string;
  servicenowBusinessOwnerDepartmentCode: string;
  servicenowSolutionName: string;
  servicenowBusinessOwnerDepartment: string;
  servicenowBusinessOwnerEmail: string;
  servicenowBusinessOwnerFirstName: string;
  servicenowBusinessOwnerInitials: string;
  servicenowBusinessOwnerLastName: string;
  servicenowLastUpdated: string;
  servicenowOrgId: string;
};

export type AwsResourcesPayload = {
  resultCount: number;
  result: AccountMetadataType[];
};

function useAccountsMetadata() {
  const Scopes = useContext(ApplicationScopesCtx);
  const { accounts, instance } = useMsal();

  const accountsMetadataQuery = useQuery({
    queryKey: ['accountsMetadata'],
    cacheTime: 1000 * 60 * 60 * 12, // 12 hours
    queryFn: async () => {
      const silentRequest = {
        scopes: [Scopes!.OVERVIEW_READ],
        account: accounts[0]
      };
      const token: AuthenticationResult = await instance.acquireTokenSilent(silentRequest);
      const endpoint = getApiConfig(
        process.env.REACT_APP_STAGE as 'staging' | 'production',
        process.env.REACT_APP_ORG as 'p934dq569e' | 'yozh4idta4'
      );
      const response = await fetch(endpoint.ACCOUNTS_API_ENDPOINT + '/metadata', {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          'x-top-api-version': 'v1'
        }
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    staleTime: 1000 * 60 * 60 * 12 // 12 hours
  });

  return {
    data: accountsMetadataQuery.data as AwsResourcesPayload,
    error: accountsMetadataQuery.error as Error,
    isLoading: accountsMetadataQuery.isLoading,
    isFetching: accountsMetadataQuery.isFetching,
    isError: accountsMetadataQuery.isError,
    isSuccess: accountsMetadataQuery.isSuccess,
    dataUpdatedAt: accountsMetadataQuery.dataUpdatedAt
  };
}

export default useAccountsMetadata;

import { Typography } from '@mui/material';
import React from 'react';
import Logo from './logo';

function LogoText() {
  return (
    <Logo>
      <Typography variant="h6" color="inherit" noWrap>
        In-Cloud
      </Typography>
    </Logo>
  );
}

export default LogoText;

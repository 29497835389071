import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Box } from '@mui/material';
import React from 'react';
import { getPages } from '../../pages';
import LinkIcon from './link-icon';

function LinkIconBar() {
  const { instance } = useMsal();
  return (
    <Box sx={{ display: 'flex' }}>
      <AuthenticatedTemplate>
        <Box sx={{ display: 'flex', mr: '10px' }}>
          {getPages(instance.getActiveAccount()).map(
            (page) =>
              page.visible && (
                <LinkIcon tooltip={page.tooltip} linkTo={page.link} key={page.link}>
                  {page.icon({ size: 'large' })}
                </LinkIcon>
              )
          )}
        </Box>
      </AuthenticatedTemplate>
    </Box>
  );
}

export default LinkIconBar;

import { createContext } from 'react';

interface ApplicationScopes {
  OVERVIEW_READ: string;
  OVERVIEW_WRITE: string;
  OVERVIEW_ADMIN: string;
}

const ApplicationScopesCtx = createContext<ApplicationScopes | null>(null);

export default ApplicationScopesCtx;
